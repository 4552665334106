import { CircularProgress } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const PageLoading = (props) => {

    const { visible = true, h = false , w = false, scale = false, color = false } = props

    if (!visible) {
        return null
    }
    
    return <Page color={color} scale={scale} w={w} h={h} >
        <CircularProgress color="primary" />
    </Page>
}

export default PageLoading

const Page = styled.main`
    width: ${p => p.w ? p.w : "100%"};
    height: ${p => p.h ? p.h : "100vh"};
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
        ${p => !!p.scale ? `transform: scale(${p.scale})` : null};
        color: ${p => !!p.color ? p.color : '#afbdd1'};
    }
`