import React from 'react'
import styled from 'styled-components';
import { DefaultCard } from '../../../components/common/DefaultCard';
import AdminHome from '../../../components/templates/home/AdminHome';
import ListClients from '../../../components/templates/home/ListClients';
import CardAdmin from '../../../components/templates/home/CardAdmin';
import Crisp from '../../../components/templates/home/Crisp';
import MenuAdmin from '../../../components/templates/home/MenuAdmin';

const HomeAdmin = () => {

    return (
        <Wrapper>
            {/* 1 */}
            <DefaultCard bg="#F1C40F" border="#B7950B" color="#212936" area="cash" h="120px">
                <AdminHome/>
            </DefaultCard>
            <DefaultCard bg="#4572f1" border="#4572f1" color="#212936" area="crisp" h="120px">
                <Crisp/>
            </DefaultCard>
            {/* 2 */}
            <DefaultCard area="card-1" h="120px">
                <CardAdmin title="Solicitudes de retiro" value="584.360,00" to="/withdrawals"/>
            </DefaultCard>
            <DefaultCard area="card-2" h="120px">
                <CardAdmin title="Comisión asesores" value="34.555,00" to="/commission"/>
            </DefaultCard>
            {/* 3 */}
            <DefaultCard area="card-3" h="120px" bg="linear-gradient(62deg, rgba(94,104,123,1) 0%, rgba(49,60,78,1) 100%);">
                <MenuAdmin title="Inversores" to="/investors-admin"/>
            </DefaultCard>
            <DefaultCard area="card-4" h="120px" bg="linear-gradient(62deg, rgba(49,60,78,1) 0%, rgba(94,104,123,1) 100%);">
                <MenuAdmin title="Asesores" to="/adviser-admin"/>
            </DefaultCard>
        </Wrapper>
    )
}

export default HomeAdmin

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 120px 120px 120px;
    grid-template-areas:"cash   cash   cash   crisp  crisp  crisp"
                        "card-1 card-1 card-1 card-2 card-2 card-2"
                        "card-3 card-3 card-3 card-4 card-4 card-4";
    gap: 1rem;
    @media(max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`