import { IconButton } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import useInvestments from '../../../../hooks/useInvestments'
import VisibilityIcon from '@material-ui/icons/Visibility';
import DefaultTable from '../../../common/DefaultTable';

const TableRender = (props) => {

    const { status, handleInfoContract } = props

    let columns = [
        { id: "id", label: "" },
        { id: "dateFrom", label: "Fecha" },
        { id: "dateTo", label: "Vence" },
        { id: "amount", label: "Monto" },
        { id: "acciones", label: "" },
    ]
    if (status === 'pendientes') {
        columns = [
            { id: "id", label: "" },
            { id: "createdAt", label: "Fecha creación" },
            { id: "amount", label: "Monto" },
            { id: "acciones", label: "" },
        ]
    }
    

    const { investments } = useInvestments(status) 

    const tableRender = () => {
        if (investments) {
            const result = investments.map((item, index) => ({
                ...item,
                id: <Id>{item?.term / 30}</Id>,
                amount: `$${item.amount}`,
                createdAt: new Date(item.createdAt).toLocaleDateString(),
                dateFrom: new Date(item.dateFrom).toLocaleDateString(),
                dateTo: new Date(item.dateTo).toLocaleDateString(),
                acciones: <IconButton disabled={status === 'pendientes' ? true : false} color="primary" onClick={() => handleInfoContract(item)} >
                    <VisibilityIcon />
                </IconButton>
            }))
            return result.reverse()
        }
    }

    if (!investments) {
        return 'Cargando'
    }

    return (
        <DefaultTable rows={tableRender()} columns={columns} pageSize={4} footer={true} />
    )
}

export default TableRender

const Id = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #B7950B;
    color: #212936;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
`