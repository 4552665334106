import { Form, Formik } from 'formik';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components'
import { alertsActions } from '../../../../redux/actions';
import { newContract } from '../../../../redux/api/investment';
import { DefaultButton } from '../../../common/DefaultButton';
import DefaultField from '../../../common/DefaultField';
import DefaultFile from '../../../common/DefaultFile';
import PageLoading from '../../../common/PageLoading';
import { Text } from '../../../common/Text'
import { investmentSchema } from '../../../schemas/investment';

const FormInvestment = (props) => {

    const { cash = 300, blocked = false } = props

    const [file, setFile] = useState(null)
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)

    const dispatch = useDispatch()
    const history = useHistory()

    // FORMIK
    const initialValues = {
        code: '',
        cash: cash
    };

    const handleSubmit = async values => {
        setLoading(true)
        const form = new FormData()
        form.append('file', file)
        form.append('amount', values.cash)
        form.append('investor_code', values.code)

        const request = await newContract(form)
        setLoading(false)
        if (request.error) {
            dispatch(alertsActions.showSnack({
                variant: 'error',
                message: '¡El código del asesor no es válido!'
            }))
        }
        else{
            setPage(1)
        }
    }

    const handleB64File = file => {
        setFile(file)
    }

    if (page === 1) {
        return <ContainerSuccess>
            <Text fontSize="20px" color="#fff">¡Inversion exitosa!</Text>
            <Text textAlign="center" fontSize="14px" color="#AFBDD1">Se añadió un nuevo paquete a tu lista de inversiones, puedes ver el estado de la inversión en la sección contratos.</Text>
            <DefaultButton variant='outlined' color='primary' onClick={() => history.push('/contracts')}>Ver contratos</DefaultButton>
        </ContainerSuccess>
    }

    return (
        <Container>
            <Text fontSize="20px" color="#fff">Nueva inversión</Text>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={investmentSchema}
            >
                {() => (
                    <FormContainer>
                        <DefaultField
                            name="code"
                            type="text"
                            label="Código de asesor"
                            variant='outlined'
                            required
                        />
                        <DefaultField
                            name="cash"
                            type="number"
                            label="(USD) Monto a invertir"
                            required
                            variant='outlined'
                            disabled={blocked}
                        />
                        <DefaultFile handleB64File={handleB64File}/>
                        {!loading ? (<DefaultButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            id="submit"
                            disabled={!file ? true : false}
                        >
                            AÑADIR PAQUETE
                        </DefaultButton>)
                        : (<PageLoading h="50px"/>)}
                    </FormContainer>
                )}
            </Formik>
        </Container>
    )
}

export default FormInvestment

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 30px;
    min-width: 320px;
    max-width: 320px;
`

const ContainerSuccess = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    gap: 30px;
    min-width: 320px;
    max-width: 320px;
`

const FormContainer = styled(Form)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    /* margin: 20px auto;
    width: 90%; */
    #submit{
        margin: 0 auto;
        width: 150px;
        margin-top: 20px;
    }
`