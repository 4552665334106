import React from "react";
import styled from "styled-components";

const ErrorPage = props => {
    const { message = "¡Ocurrió un error!", color = "#fff", mh = false } = props

    return <ErrorpageWrapper>
        <Message mh={mh} color={color}>{message}</Message>
    </ErrorpageWrapper>
}

export default ErrorPage;

const ErrorpageWrapper = styled.div`
    height: 100%;
    min-height: ${p => p.mh};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const Message = styled.div`
    font-size: 1rem;
    /* font-weight: 700; */
    color: ${p => p.color};
    margin-bottom: 1rem;
    text-transform: uppercase;
`