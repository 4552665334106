import React from 'react'
import styled from 'styled-components';
import { DefaultCard } from '../../components/common/DefaultCard';
import ImageProfile from '../../components/templates/profile/ImageProfile';
import ProfileData from '../../components/templates/profile/ProfileData';


const Profile = () => {

    return (
        <Wrapper>
            <ImageProfile />
            <DefaultCard h="auto">
                <ProfileData/>
            </DefaultCard>
        </Wrapper>
    )
}

export default Profile

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 208px 1fr;
    /* grid-template-rows: 245px auto; */
    /* grid-template-areas:"image  image  list list list list"; */
    gap: 1rem;
    @media(max-width: 768px){
        display: flex;
        flex-direction: column;
    }
`