import React from 'react';

import { Field } from 'formik';
import { MenuItem, Select } from '@material-ui/core';
import styled from 'styled-components';

const DefaultSelect = (props) => {
    const { name, label, options } = props;

    // RETURN
    return (
        <Field
            as={SelectFake}
            name={name}
            label={label}
            variant="standard"
            color="primary"
            size="small"
            fullWidth
            {...props}
        >
            {options.map((value) => (
                <MenuItem value={value.id} key={value.id}>
                    {value.name || value.nombre}
                </MenuItem>
            ))}
        </Field>
    );
};

const SelectFake = styled(Select)`
    max-height: 37.6667px;
    fieldset{
        border-color: #f0f0f0;
    }
    label{
        color: #f0f0f0;
    }
    .MuiInputBase-input{
        color: white;
    }
    svg{
        color: white;
    }
`

export default DefaultSelect;
