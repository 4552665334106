import React from 'react'
import styled from 'styled-components';
import InversorForm from '../../molecules/auth/InversorForm';

const FormAdmin = () => {

    return (
        <Wrapper>
            <InversorForm rol="administrator" />
        </Wrapper>
    )
}

export default FormAdmin

const Wrapper = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    form{
        width: 100%;
    }
`