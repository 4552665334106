import React from 'react'
import styled from 'styled-components'
// import useCashUser from '../../../hooks/useCashUser'
import useInvestments from '../../../hooks/useInvestments'
// import { DefaultButton } from '../../common/DefaultButton'
import { Text } from '../../common/Text'

const CashIverted = () => {

    // const { values } = useCashUser()

    const { total, profit } = useInvestments('vigentes')

    // if (values === null) {
    //     return <PageLoading h="100%"/>
    // }

    // if (values === false) {
    //     return <ErrorPage color="#212936"/>
    // }

    return (
        <Container>
            <Body>
                <Text fontSize="20px" fontWeight="500">Dinero invertido</Text>
                <Text fontSize="34px" fontWeight="500">${total}</Text>
            </Body>
            <Body>
                <Text fontSize="20px" fontWeight="500">Ganancias totales</Text>
                <Text fontSize="34px" fontWeight="500">${profit.toFixed(2)}</Text>
            </Body>
            <Text color="#313C4E" fontWeight="400" fontSize="12px">Retiro de ganancias del 1 al 10 de cada mes.</Text>
        </Container>
    )
}

export default CashIverted

const Container = styled.div`
    padding-top: 10px;
    height: 268px;
    max-height: auto;
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    flex-direction: column;
    gap: 16px;
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

// const Footer = styled.div`
//     height: 20%;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     gap: 1rem;
// `