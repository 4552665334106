import { Button } from '@material-ui/core';
import React from 'react'
import styled from "styled-components";
import { Text } from '../../common/Text';
import { useHistory } from "react-router-dom";

const CardAdmin = (props) => {

    const { title, value, to } = props

    const history = useHistory()

    return (
        <WithdrawalRequestContent>
            <Info>
                <div>
                    <Text fontSize="20px" color="#FFFFFF">{title}</Text>
                    <Text fontSize="36px" color="#F1C40F">${value}</Text>
                </div>
            </Info>
            <ButtonWrapper>
                <Button onClick={() => history.push(to)} variant='contained' color='primary'>GESTIONAR</Button>
            </ButtonWrapper>
        </WithdrawalRequestContent>
    )
}

export default CardAdmin

const WithdrawalRequestContent = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    min-height: 90px;
    height: 90px;
`

const Info = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    div{
        display: flex;
        flex-direction: column;
        gap: 7px;
    }
`

const ButtonWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`