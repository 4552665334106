import { useEffect, useState } from "react"
import { getStates } from "../redux/api/utils"

const useStates = (id = false) => {
    const [states, setStates] = useState(null)
    const [myState, setMyState] = useState(null)

    useEffect(() => {
        !states && getStatesServices()
    })

    useEffect(() => {
        !!states && !!id && getStateById(states, id)
    }, [id, states])
    
    
    const getStateById = (array, id) => {
        const result = array.find(state => state.id === id)
        setMyState(result)
    }

    const getStatesServices = async () => {
        const request = await getStates()
        if (request.error) {
            
        }
        else{
            setStates(request)
        }
    }

    return { states, myState }
}

export default useStates