import * as yup from 'yup';

export const simulatorSchema = yup.object().shape({
    name: yup
        .string()
        .required('Nombre requerido')
        .min(3, 'Mínimo 3 caracteres')
        .max(16, 'Máximo 16 caracteres'),
    lastName: yup
        .string()
        .required('Apellido requerido')
        .min(3, 'Mínimo 3 caracteres')
        .max(16, 'Máximo 16 caracteres'),
    dni: yup
        .string()
        .required('Documento requerido')
        .min(7, 'Mínimo 7 caracteres')
        .max(8, 'Máximo 8 caracteres'),
    adress: yup
        .string()
        .required('Dirección requerido')
        .min(5, 'Mínimo 5 caracteres')
        .max(128, 'Máximo 128 caracteres'),
    cash: yup
        .number()
        .min(300, 'Valor mínimo 300USD')
        .max(1000000, 'Valor máximo 1000000USD')
        .required('Monto requerido'),
    state: yup
        .number()
        .required('Provincia inválida'),
    package: yup
        .number()
        .required('Paquete inválido'),    
});