import { Provider } from "react-redux";
import Routes from "./Routes";
import { MaterialThemeProvider } from "./theme/MaterialThemeProvider";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import PageLoading from "./components/common/PageLoading";
import RoutesWithAlert from "./hoc/Alert";

const Application = (props) => {

  const { store, persistor } = props

  console.info(`Workspace: %c ${process.env.REACT_APP_WORKSPACE}`, 'background: #00000000; color: #528bab')

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        {bootstraped => {
          return [
            <PageLoading visible={!bootstraped} />,
            bootstraped && (
              <BrowserRouter basename={""}>
                <MaterialThemeProvider>
                  <RoutesWithAlert />
                </MaterialThemeProvider>
              </BrowserRouter>
            )
          ]

        }}
      </PersistGate>
    </Provider>
  );
}

export default Application;
