import React from 'react'
import styled from 'styled-components'
import ImageBanner from '../../components/templates/auth/ImageBanner'
import SignUpForm from '../../components/templates/auth/SignUpForm'

const SignUp = () => {
    return (
        <SignUpWrapper>
            <SignUpForm/>
            <ImageBanner/>
        </SignUpWrapper>
    )
}

export default SignUp

const SignUpWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media(max-width: 768px){
        display: flex;
        flex-direction: column;
    }
`