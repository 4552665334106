import * as React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { DefaultButton } from './DefaultButton';


export default function DefaultDialog(props) {

    const { title = "Abrir" , variant = "contained", color="primary", size = 'medium', fullWidth = false, component } = props


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <DefaultButton fullWidth={fullWidth} variant={variant} color={color} size={size} onClick={handleClickOpen}>
                {title}
            </DefaultButton>
            <DialogFake
                onClose={handleClose}
                open={open}
            >
                <IconButtonFake onClick={handleClose}>
                    <CloseIcon/>
                </IconButtonFake>
                <Container>
                    {component}
                </Container>
            </DialogFake>
        </div>
    );
}

const Container = styled.div`
    min-width: 250px;
    min-height: auto;
    background-color: #212936;
    padding: 40px 10px 10px 10px;
`

const IconButtonFake = styled(IconButton)`
    position: absolute;
    right: 0;
    svg{
        color: white;
    }
`

const DialogFake = styled(Dialog)`
    .MuiPaper-rounded{
        border-radius: 0px;
    }
`