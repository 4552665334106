import * as yup from 'yup';

export const loginSchema = yup.object().shape({
    email: yup
        .string()
        .email('Formato incorrecto')
        .required('Correo requerido')
        .max(320, 'Máximo 320 caracteres'),
    password: yup
        .string()
        .min(8, 'Mínimo 8 caracteres')
        .max(20, 'Máximo 20 caracteres')
        .matches(/^.*(((?=.*[a-z]){1})((?=.*[A-Z]){1}))(((?=.*\W){1})|(?=.*[0-9])).*$/ , 'La contraseña debe contener al menos una mayuscula, una minuscula, un caracter numérico y un caracter especial')
        .required('Contraseña requerida')
});

export const registerSchema = yup.object().shape({
    phone: yup
        .string()
        .min(8, 'Mínimo 8 caracteres')
        .max(12, 'Máximo 12 caracteres')
        .required('Telefono requerido'),
    name: yup
        .string()
        .min(3, 'Mínimo 3 caracteres')
        .max(16, 'Máximo 16 caracteres')
        .required('Nombre requerido'),
    lastName: yup
        .string()
        .min(3, 'Mínimo 3 caracteres')
        .max(16, 'Máximo 16 caracteres')
        .required('Nombre requerido'),
    email: yup
        .string()
        .email('Formato incorrecto')
        .required('Correo requerido')
        .max(320, 'Máximo 320 caracteres'),
    password: yup
        .string()
        .min(8, 'Mínimo 8 caracteres')
        .max(50, 'Máximo 50 caracteres')
        .matches(/^.*(((?=.*[a-z]){1})((?=.*[A-Z]){1}))(((?=.*\W){1})|(?=.*[0-9])).*$/ , 'La contraseña debe contener al menos una mayuscula, una minuscula, un caracter numérico y un caracter especial')
        .required('Contraseña requerida'),
    changepassword: yup
        .string()
        .required('Contraseña requerida')
        .when('password', {
            is: (val) => (val && val.length > 0 ? true : false),
            then: yup
                .string()
                .oneOf([yup.ref('password')], 'Las contraseñas no coinciden')
        })
});

export const updateProfileSchema = yup.object().shape({
    name: yup
        .string()
        .min(3, 'Mínimo 3 caracteres')
        .max(50, 'Máximo 50 caracteres')
        .required('Nombre requerido'),
    lastName: yup
        .string()
        .min(3, 'Mínimo 3 caracteres')
        .max(50, 'Máximo 50 caracteres')
        .required('Apellido requerido'),
    email: yup
        .string()
        .email('Formato incorrecto')
        .required('Correo requerido')
        .max(320, 'Máximo 320 caracteres'),
    dni: yup
        .string()
        .min(7, 'Mínimo 7 caracteres')
        .max(8, 'Máximo 8 caracteres')
        .required('Documento no válido'),
    adress: yup
        .string()
        .min(8, 'Mínimo 8 caracteres')
        .max(50, 'Máximo 256 caracteres')
        .required('Direccion invalida'),
    state: yup
        .number()
        .required('Provincia inválida'),
    birthdate: yup
        .string()
        .required('Fecha inválida'),
    cbu: yup
        .string()
        .min(6, 'Mínimo de 6 dígitos')
        .max(20, 'Máximo de 20 dígitos')
        .required('CBU inválido'),
    wallet: yup
        .string()
        .min(6, 'Mínimo de 6 dígitos')
        .max(36, 'Máximo de 36 dígitos')
        .required('Wallet inválida'),
});