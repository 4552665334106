import * as types from "../types/auth";

const actions = {};

// LOGIN
actions.loginUserRequest = (data) => {
	return {
		type: types.LOGIN_USER_REQUEST,
		payload: data,
	};
};

actions.loginUserSuccess = (payload) => {
	return {
		type: types.LOGIN_USER_SUCCESS,
		payload: payload,
	};
};

actions.loginUserFail = (error) => {
	return {
		type: types.LOGIN_USER_FAIL,
		payload: error,
	};
};

// Logout
actions.logoutUser = () => {
	return {
		type: types.LOGOUT_USER,
	};
};

// UPDATE INFO
actions.changeDataUser = (payload) => {
	return {
		type: types.UPDATE_USER,
		payload: payload,
	};
};

export { actions };