import React from 'react'
import styled from 'styled-components'
import { Divider } from '../../common/Divider'
import { Text } from '../../common/Text'
// import { DefaultButton } from '../../common/DefaultButton'
import EditIcon from '@material-ui/icons/Edit';
import IconButton from "@material-ui/core/IconButton";
import { useSelector } from 'react-redux'
import { useState } from 'react'
import EditProfile from './EditProfile'
import useStates from '../../../hooks/useStates';

const ProfileData = () => {

    const { user } = useSelector(state => state.auth)

    const [page, setPage] = useState(false)

    const { states, myState } = useStates(user.state)

    const handleEditProfile = () => {
        setPage(!page)
    }

    if (!!page && !!states) {
        return <EditProfile states={states} handleEditProfile={handleEditProfile}/>
    }

    return (
        <Container>
            <TextFake color="#fff" fontSize="20px">
                <div>Datos personales</div>
                <IconButton onClick={handleEditProfile} color="primary">
                    <EditIcon />
                </IconButton>
            </TextFake>
            <Data columns="300px auto">
                <Text fontSize="14px">Nombre</Text>
                <Text fontSize="14px" color="#fff">{`${user.name || '-'}`}</Text>
                <Text fontSize="14px">Apellido</Text>
                <Text fontSize="14px" color="#fff">{`${user.lastName || '-'}`}</Text>
                <Text fontSize="14px">DNI</Text>
                <Text fontSize="14px" color="#fff">{user.dni || "-"}</Text>
                <Text fontSize="14px">Celular</Text>
                <Text fontSize="14px" color="#fff">{user.phone || "-"}</Text>
                <Text fontSize="14px">Dirección</Text>
                <Text fontSize="14px" color="#fff">{user.adress || '- '}, {!!myState ? myState.name : user.state?.name || '-'}</Text>
                <Text fontSize="14px">Fecha de nacimiento</Text>
                <Text fontSize="14px" color="#fff">{user.birthdate || "-"}</Text>
            </Data>
            <Divider/>
            <Text color="#fff" fontSize="20px">Inicio de sesión</Text>
            <Data columns="300px auto">
                <Text fontSize="14px"> Mail</Text>
                <Text fontSize="14px" color="#fff">{user.email}</Text>
                <Text fontSize="14px">Contraseña</Text>
                <Text fontSize="14px" color="#fff">********</Text>
            </Data>
            <Divider/>
            <Text color="#fff" fontSize="20px">Métodos de pago</Text>
            <Data columns="300px auto">
                <Text fontSize="14px">Transferencia</Text>
                <Text fontSize="14px" color="#fff">CBU: {user.cbu || '-'}</Text>
                <Text fontSize="14px">Wallet</Text>
                <Text fontSize="14px" color="#fff">{user.wallet || '-'}</Text>
            </Data>
        </Container>
    )
}

export default ProfileData

const Container = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 15px;
`

const Data = styled.div`
    display: grid;
    row-gap: 15px;
    grid-template-columns: ${p => p.columns};
    @media(max-width: 768px){
        display: flex;
        flex-direction: column;
    }
`

const Fake = styled.div`
    height: 33px;
`

const TextFake = styled(Text)`
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
`