import React from 'react'
import styled from 'styled-components'
import { DefaultCard } from '../../components/common/DefaultCard'
import DefaultTable from '../../components/common/DefaultTable'
import PageLoading from '../../components/common/PageLoading'
import { Text } from '../../components/common/Text'
import useInvestments from '../../hooks/useInvestments'

export const Investments = () => {

    const columns = [
        { id: "term", label: "" },
        { id: "dateFrom", label: "Fecha" },
        { id: "dateTo", label: "Vence" },
        { id: "amount", label: "Monto" },
        { id: "current_profit", label: "Ganancia" },
        { id: "status", label: "Estado" },
        { id: "acciones", label: "" },
    ]

    const { investments } = useInvestments()

    const tableRender = () => {

        if (investments) {
            const result = investments.map((item, index) => {
                let isDefeated = false
                const nowDate = new Date().getTime()
                const dateTo = new Date(item.dateTo).getTime()
                if (nowDate > dateTo && (item.status !== 'PENDIENTE' && item.status !== 'RECHAZADO')) {
                    isDefeated = true
                }
                return {
                    ...item,
                    term: <Id>{(item.term / 30)}</Id>,
                    amount: `$${item.amount}`,
                    dateFrom: item.status !== 'PENDIENTE' && item.status !== 'RECHAZADO' ? new Date(item.dateFrom).toLocaleDateString() : '-',
                    dateTo: item.status !== 'PENDIENTE' && item.status !== 'RECHAZADO' ? new Date(item.dateTo).toLocaleDateString() : '-',
                    current_profit: item.current_profit ? `$${item.current_profit}` : '-',
                    status: <Text color={isDefeated ? '#D24335' : item.status === 'PENDIENTE' ? '#B7950B' : item.status === 'APROBADO' ? '#58D68D' : '#D24335'}>{isDefeated && (item.status !== 'PENDIENTE' && item.status !== 'RECHAZADO') ? 'Vencido' : item.status.toTitleCase()}</Text>
                }
            })
            return result
        }
    }

    if (!investments) {
        return <DefaultCard>
            <PageLoading />
        </DefaultCard>
    }

    return (
        <InvestmentsWrapper>
            <DefaultCard>
                <Text fontSize="20px" color="#fff">Inversiones</Text>
                <DefaultTable columns={columns} rows={tableRender()} pageSize="10" footer={true} />
            </DefaultCard>
        </InvestmentsWrapper>
    )
}

const InvestmentsWrapper = styled.div``

const Id = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #B7950B;
    color: #212936;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
`