import React from 'react'
import styled from 'styled-components'
import bg from "../../../assets/images/FONDO.png";
import { Text } from '../../common/Text';

const ImageBanner = () => {
    return (
        <ImageBannerWrapper bg={bg}>
            <div>
                <Text color="white" fontSize="64px" fontWeight="800" >FONDOS <Text>DE INVERSIÓN</Text></Text>
                <Text color="white" fontSize="20px" fontWeight="500" >Lorem ipsum dolor sit amet, consectur adipisci elit, sed do eiusmod tempor incididunt ut labor et dolore magna aliqua. Ut enim ad minim vena, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Text>
            </div>
        </ImageBannerWrapper>
    )
}

export default ImageBanner

const ImageBannerWrapper = styled.div`
    background-image: url(${p => p.bg});
    background-position: center;
    background-size: cover;
    width: calc(100% - 100px);
    height: 100%;
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width: 768px){
        display: none;
    }
`