import { useEffect } from "react";

export const useHelpChat = (user, isAuth, route) => {

    const id = 'bcbd7e7e-dd0c-4842-8a91-3b7bf460cf5c'
    // EFFECTS
    useEffect(() => {
        if (route === 'private' && isAuth) {
            window.$crisp = [];
            window.CRISP_WEBSITE_ID = id;
            window.CRISP_TOKEN_ID = user.id;
            window.$crisp.push(["set", "user:email", [user.email]]);
            window.$crisp.push(["set", "user:nickname", [user.name + ' ' + user.lastName]]);
            window.$crisp.push(["set", "user:avatar", [user.image]]);

            (function () {
                var d = document;
                var s = d.createElement("script");
                s.src = "https://client.crisp.chat/l.js";
                s.async = 1;
                d.getElementsByTagName("head")[0].appendChild(s);
            })();
        }
        // Public
        if (route === 'public') {
            window.$crisp = [];
            window.CRISP_WEBSITE_ID = id;
            window.$crisp.push(['do', 'session:reset', [false]]);
            window.CRISP_RUNTIME_CONFIG = {
                session_merge: true
            };

            (function () {
                var d = document;
                var s = d.createElement('script');
                s.id = 'crisp';
                s.src = 'https://client.crisp.chat/l.js';
                s.async = 1;
                d.getElementsByTagName('head')[0].appendChild(s);
            })();
        }

        return () => {
            console.log("Return");
            const crisp = document.getElementById('crisp');
            crisp && crisp.remove();
            const chat = document.getElementById('crisp-chatbox');
            chat && chat.remove();
        }
    }, []);

    return { hash: id }
}