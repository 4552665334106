import React from 'react'
import { useState } from 'react';
import styled from 'styled-components';
import { DefaultCard } from '../../common/DefaultCard';
import TabsComponent from '../../common/TabsComponent';
import { Text } from '../../common/Text';
import TableRender from '../../ui/organisms/contracts/TableRender';


const ContractsList = (props) => {

    const { handleInfoContract, handleReset } = props

    const tabs = [
        { label: `VIGENTES`, id: 0 },
        { label: `PENDIENTES`, id: 1 },
        { label: `VENCIDOS`, id: 2 },
    ];

    const [tab, setTab] = useState(0);

    const handleTab = (e, value) => {
        setTab(value);
        handleReset()
    };

    return (
        <Container>
            <Text fontSize="20px" color="#fff">Contratos</Text>
            <TabsComponent bg="#212936" tab={tab} handleTab={handleTab} tabs={tabs} w="33.33%" />
            {tab === 0 && <TableRender handleInfoContract={handleInfoContract} status={'vigentes'}/>}
            {tab === 1 && <TableRender handleInfoContract={handleInfoContract} status={'pendientes'}/>}
            {tab === 2 && <TableRender handleInfoContract={handleInfoContract} status={'vencidos'}/>}
        </Container>
    )
}

export default ContractsList

const Container = styled(DefaultCard)`
    display: flex;
    flex-direction: column;
    gap: 15px;
`