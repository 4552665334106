import * as yup from 'yup';

export const investmentSchema = yup.object().shape({
    code: yup
        .string()
        .required('Código requerido')
        .min(6, 'Mínimo 6 caracteres')
        .max(8, 'Máximo 8 caracteres'),
    cash: yup
        .number()
        .min(300, 'Valor mínimo 300USD')
        .max(1000000, 'Valor máximo 1000000USD')
        .required('Monto requerida')
});