import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import React, { useState } from 'react'
import styled from 'styled-components'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Text } from '../../common/Text'

const PrefitInfo = () => {

    const [expanded, setExpanded] = useState(false);

    return (
        <AccordionFake id="info" expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <AccordionSummaryFake
                isOpen={expanded}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Text fontSize="15px" color={true ? '#F1C40F' : '#fff'}>Tabla comisional</Text>
            </AccordionSummaryFake>
            <AccordionDetailsFake isOpen={expanded}>
                <Prefit>
                    <Text fontSize="14px" color={'#fff'}>Asesor a 3 meses</Text>
                    <Text fontSize="14px" color={'#fff'}>5%</Text>
                </Prefit>
                <Prefit>
                    <Text fontSize="14px" color={'#fff'}>Asesor a 6 meses</Text>
                    <Text fontSize="14px" color={'#fff'}>4%</Text>
                </Prefit>
                <Prefit>
                    <Text fontSize="14px" color={'#fff'}>Asesor a 12 meses</Text>
                    <Text fontSize="14px" color={'#fff'}>3.5%</Text>
                </Prefit>
            </AccordionDetailsFake>
        </AccordionFake>
    )
}

export default PrefitInfo

const AccordionFake = styled(Accordion)`
    border-radius: 4px;
    background: #212936;
    grid-area: prefit;
    margin: 0;
`

const AccordionSummaryFake = styled(AccordionSummary)`
    #info{
        margin: 0px 2px;
    }
    background: #212936;
    border-radius: 4px;
    border: 1px solid #F1C40F;
    border-bottom: ${p => p.isOpen && '0px'};
    border-bottom-right-radius: ${p => p.isOpen && '0px'};
    border-bottom-left-radius: ${p => p.isOpen && '0px'};
    box-shadow: none;
    padding: 0 24px;
    svg{
        color: ${p => p.isOpen ? '#F1C40F' : '#F1C40F'};
    }
    margin: 0;
`

const AccordionDetailsFake = styled(AccordionDetails)`
    background: #212936;
    border: 1px solid #F1C40F;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 24px;
    padding-top: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
`

const Prefit = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`