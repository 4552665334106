import React from 'react'
import styled from 'styled-components'
import ImageBanner from '../../components/templates/auth/ImageBanner'
import AuthForm from '../../components/templates/auth/AuthForm'

const Login = () => {
    return (
        <LoginWrapper>
            <AuthForm/>
            <ImageBanner/>
        </LoginWrapper>
    )
}

export default Login

const LoginWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media(max-width: 768px){
        display: flex;
        flex-direction: column;
    }
`