import { useEffect, useState } from "react"
import { getCurrencyCripto } from "../redux/api/cripto"

const useCripto = () => {

    const [currency, setCurrency] = useState(null)

    useEffect(() => {
        !currency && getCurrency()
    }, [])

    const getCurrency = async () => {
        const req = await getCurrencyCripto()
        if (req.error) {
            setCurrency(false)
        }
        else{
            // const format = req.map((item, index) => ({
            //     id: index,
            //     name: item.id,
            //     value: item["1d"].price_change_pct,
            //     image: item.logo_url,
            //     price: `$${(parseInt(item.price, 10)).toFixed(2)}`
            // }))
            setCurrency(req)
            console.log(req);
        }
    }
    
    return currency
}

export default useCripto
