import React from 'react';
import SnackGeneric from '../components/common/SearchGeneric';
import { useSnack } from '../hooks/useSnack';
import Routes from '../Routes';

const RoutesWithAlert = () => {
    const { isOpen, variant, message, closeSnack } = useSnack();

    return (
        <>
            <Routes />
            <SnackGeneric
                show={isOpen}
                message={message}
                variant={variant}
                handleClose={closeSnack}
            />
        </>
    );
};

export default RoutesWithAlert;
