import React from 'react';
import styled from 'styled-components';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
}

const TabsComponent = (props) => {
    const { tab, handleTab, tabs, variant, bg = '#2b3648', w = "200px" } = props;

    const tabVariant = variant !== undefined ? variant : 'scrollable';

    // RETURN
    return (
        <FakeAppBar bg={bg} w={w} position="static" color="secondary">
            <TabsFake
                value={tab}
                onChange={handleTab}
                indicatorColor="primary"
                textColor="primary"
                variant={tabVariant}
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                {tabs.map((item) => (
                    <FakeTab
                        label={item.label}
                        key={item.id}
                        {...a11yProps(item.id)}
                        value={item.id}
                    />
                ))}
            </TabsFake>
        </FakeAppBar>
    );
};

export default TabsComponent;

const FakeAppBar = styled(AppBar)`
    background-color: ${p => p.bg};
    box-shadow: none;
    border-bottom: 1px solid #AFBDD1;
    z-index: 1;
    .MuiTab-textColorPrimary {
        color: #AFBDD1;
    }
    .Mui-selected{
        color: #c6a51a;
    }
    .MuiTab-root{
        min-width: ${p => p.w};
    }
    svg{
        color: #AFBDD1;
    }
`;

const FakeTab = styled(Tab)`
    font-weight: 700;
    text-transform: none;
`;

const TabsFake = styled(Tabs)``;
