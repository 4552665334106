import React, { useState } from "react";
import styled from "styled-components";
import Aside from "../aside/Aside";
import Navbar from "../navbar/Navbar";

const Layout = (props) => {

	const [menu, setMenu] = useState(false)

	const handleMenu = (state) => {
		setMenu(state)
	}

	// RETURN
	return (
		<>
			<Navbar handleMenu={handleMenu}/>
			<Aside menu={menu} handleMenu={handleMenu}/>
			<Main>
				{props.children}
			</Main>
		</>
	);
};

export default Layout;


const Main = styled.main`
	padding: 2rem 10rem 2rem calc(10rem + 265px);
	transition: .5s ease-in-out all;
	margin-top: 64px;
	height: auto;
	max-height: calc(100vh - 128px);
	overflow-y: auto;
	background-color: #2B3648;
	@media (max-width: 1280px) {
		padding: 2rem 2rem 2rem calc(2rem + 265px);
	}
	@media (max-width: 768px) {
		padding: 3rem 1rem 1rem 1rem;
	}
`;