import axios from "axios"
import { BASEURL } from "./config";

const url = BASEURL

export async function getStates() {
	try {
		const req = await axios.get(`${url}/states`)
		return req.data
	} catch (error) {
		return { error }
	}
}