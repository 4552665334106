import * as yup from 'yup';

export const createAdviser = yup.object().shape({
    name: yup
        .string()
        .min(3, 'Mínimo 3 caracteres')
        .max(50, 'Máximo 50 caracteres')
        .required('Nombre requerido'),
    email: yup
        .string()
        .email('Formato incorrecto')
        .required('Correo requerido')
        .max(320, 'Máximo 320 caracteres'),
    password: yup
        .string()
        .min(8, 'Mínimo 8 caracteres')
        .max(50, 'Máximo 50 caracteres')
        .matches(/^.*(((?=.*[a-z]){1})((?=.*[A-Z]){1}))(((?=.*\W){1})|(?=.*[0-9])).*$/, 'La contraseña debe contener al menos una mayuscula, una minuscula, un caracter numérico y un caracter especial')
        .required('Contraseña requerida'),
    phone: yup
        .string()
        .min(8, 'Mínimo 8 caracteres')
        .max(12, 'Máximo 12 caracteres')
        .required('Telefono requerido'),
});