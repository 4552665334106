import { useEffect, useState } from "react"

const useLocatioPage = () => {

    const [page, setPage] = useState(null)
    const location = window.location.pathname

    useEffect(() => {
        getPage()
    }, [location])

    const getPage = () => {
        const cases = {
            '/home': 'Inicio',
            '/profile': 'Mi perfil',
            '/investments': 'Inversiones',
            '/contracts': 'Contratos',
            '/bonus': 'Bono trimestral',
            '/simulator': 'Simulador de contratos',
            '/adviser': 'Inicio',
            '/clients': 'Cartera de clientes',
            '/codes': 'Códigos de asesor',
            '/frequent-questions': 'Preguntas frecuentes',
            '/admin': 'Dashboard',
            '/withdrawals': 'Solicitudes de retiro',
            '/commission': 'Comisión de asesores',
        }
        setPage(cases[location])
    }
    
    return { title: page }
}

export default useLocatioPage
