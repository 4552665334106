import React, { useState } from 'react';
import styled from 'styled-components';
import { Text } from './Text';
import AttachFileIcon from '@material-ui/icons/AttachFile';


const DefaultFile = props => {

    const { handleB64File, title = "ADJUNTAR ARCHIVO" } = props

    const [fileState, setFileState] = useState(null)

    const handleChange = async e => {
        const file = e.target.files[0]
        if (file.size >= 2e+6) {
            alert('Tamaño máxido 2mb')
            return
        }
        const size = formatSizeUnits(file.size)
        if (file.type === "application/pdf" || file.type === "image/jpeg" || file.type === "image/png") {
            setFileState({
                name: file.name,
                size: size
            })
        }
        else {
            alert('Formato no válido')
            setFileState(null)
            return
        }
        handleB64File(file, file.name || '')
    }

    const formatSizeUnits = (bytes) => {
        if (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
        else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
        else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(2) + " KB"; }
        else if (bytes > 1) { bytes = bytes + " bytes"; }
        else if (bytes === 1) { bytes = bytes + " byte"; }
        else { bytes = "0 bytes"; }
        return bytes;
    }

    return <InputFileWrapper>
        <LabelInput title="Formatos permitidos, PDF, JPG, JPEG, PNG" for="file">
            {/* Si el input esta vacio */}
            {!fileState && (
                <>
                    {/* <ButtonBlueFake>Seleccionar archivo</ButtonBlueFake> */}
                    <TextFake fontSize="14px" color="#F1C40F"><AttachFileIcon /> {title}</TextFake>
                </>
            )}
            {/* Si contiene algo */}
            {!!fileState && (
                <>
                    {/* <ButtonBlueFake><InsertDriveFileIcon/> {fileState.name}</ButtonBlueFake> */}
                    <TextFile fontSize="14px" color="#F1C40F">{fileState.name}</TextFile>
                </>
            )}
        </LabelInput>
        <File
            onChange={handleChange}
            id="file"
            type="file"
        />
    </InputFileWrapper>
}

export default DefaultFile;

const InputFileWrapper = styled.div`
    /* padding: 1rem; */
    /* margin: 1rem 0; */
    width: auto;
`

const File = styled.input`
    display: none;
`

const LabelInput = styled.label`
    display: flex;
    width: calc(100% - 2px);
    height: 36px;
    border: 1px #F1C40F solid;
    /* padding: .5rem 1rem; */
    color: #F1C40F;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .5s ease-in-out all;
    border-radius: 4px;
    flex-direction: column;
`

const TextFake = styled(Text)`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
`

const TextFile = styled(Text)`
    width: 230px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    justify-content: center;
`