import React from 'react'
import styled from 'styled-components'
import useCripto from '../../../hooks/useBitso'
// import { socket } from '../../../helpers/socket.helper'
import DefaultTable from '../../common/DefaultTable'
import ErrorPage from '../../common/ErrorPage'
import PageLoading from '../../common/PageLoading'
// import { DefaultButton } from '../../common/DefaultButton'
import { Text } from '../../common/Text'


const Quotes = () => {

    const currency = useCripto()

    
    const columns = [
        // { id: "id", label: "" },
        { id: "symbol", label: "Divisa" },
        { id: "value", label: "24h%" },
        { id: "current_price", label: "Precio" },
    ]

    const renderTable = (array) => {
        console.log(array);
        return array.map(item => ({
            ...item,
            // id: <Id img={item.image}/>,
            current_price: `$${item?.current_price?.toFixed(2)}`,
            symbol: item.symbol.toUpperCase(),
            value: <Text color={item.value < 0 ? '#D24335' : '#58D68D'}>{item.price_change_percentage_24h?.toFixed(2)}%</Text>
        }))
    }

    if (currency === null) {
        return <PageLoading h="100%"/>
    }

    if (currency === false) {
        return <Container>
            <ErrorPage/>
        </Container>
    }

    console.log(renderTable(currency));
    return (
        <Container>
            <Text fontSize="20px" fontWeight="500">Cotizaciones</Text>
            <DefaultTable pageSize={10} columns={columns} rows={renderTable(currency)} footer={false} />
        </Container>
    )
}

export default Quotes

const Container = styled.div`
    min-height: 500px;
    height: 500px;
    max-height: auto;
    display: flex;
    flex-direction: column;
    /* gap: .8rem; */
`