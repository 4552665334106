import React from 'react'
import styled from 'styled-components'
import { Img } from '../../common/Image'
import logo from "../../../assets/images/logo_login.png";
import { Text } from '../../common/Text';
import { DefaultButton } from '../../common/DefaultButton';
import { useHistory } from 'react-router-dom';
import FormRegister from '../../ui/organisms/auth/FormRegister';

const SignUpForm = () => {

    const history = useHistory()

    return (
        <SignUpWrapper>
            <Img w="262px" src={logo}/>
            <Text fontSize="24px" fontWeight="500">Únete como inversor/a!</Text>
            <FormRegister/>
            <Footer>
                <Text>¿Ya tienes cuenta?</Text>
                <DefaultButton color='primary' onClick={() => history.push('../auth/login')}>INICIAR SESIÓN</DefaultButton>
            </Footer>
        </SignUpWrapper>
    )
}

export default SignUpForm

const SignUpWrapper = styled.div`
    padding: 0 20px;
    width: calc(100% - 40px);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 25px;
`

const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
`