import React from 'react'
import styled from 'styled-components'
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';

const Crisp = () => {
  return (
    <Button onClick={() => window.open('https://app.crisp.chat/website/bcbd7e7e-dd0c-4842-8a91-3b7bf460cf5c/inbox/')}>
        <HeadsetMicIcon/>
        Crisp
    </Button>
  )
}

export default Crisp

const Button = styled.div`
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    font-size: 36px;
    color: white;
    svg{
        font-size: 36px;
    }
`