import React from 'react'
import styled from 'styled-components'
import { DefaultButton } from '../../common/DefaultButton'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Text } from '../../common/Text';
import { DefaultCard } from '../../common/DefaultCard';
import SimulatorPdf from '../../ui/organisms/simulator/Simulator';

const ContractsSimulatorRender = (props) => {

    const { values } = props 

    const generatePDF = () => {
        window.html2canvas = html2canvas;

        let content = document.getElementById('contrato');
        // content.style.scale = 2

        const options = {
            background: 'white',
            scrollY: -window.scrollY,
            scrollX: -window.scrollX
        };

        html2canvas(content, options)
            .then((canvas) => {
                const img = canvas.toDataURL('image/JPEG', '1.0');
                let doc = new jsPDF('p', 'mm', 'a4');

                const pageWidth = doc.internal.pageSize.getWidth() * 1;
                const pageHeight = doc.internal.pageSize.getHeight() * 1;

                const widthRatio = pageWidth / canvas.width;
                const heightRatio = pageHeight / canvas.height;
                const ratio =
                    widthRatio > heightRatio ? heightRatio : widthRatio;

                const canvasWidth = canvas.width * ratio;
                const canvasHeight = canvas.height * ratio;

                const marginX = (pageWidth - canvasWidth) / 2;
                const marginY = (pageHeight - canvasHeight) / 2;

                doc.addImage(
                    img,
                    'PNG',
                    marginX,
                    marginY,
                    canvasWidth,
                    canvasHeight,
                    undefined,
                    'FAST'
                );

                return doc;
            })
            .then((doc) => {
                doc.save('contrato.pdf');
            });
    };

    
    if (!values) {
        return <DefaultCardContainer>
            <Text>Simula el contrato para visualizar</Text>
        </DefaultCardContainer>
    }

    return (
        <Container>
            <SimulatorPdf values={values} advisor={values?.adviser} contract={''} type={(values.term / 30)}/>
            <DefaultButtonFake onClick={generatePDF} startIcon={<CloudDownloadIcon/>} variant='contained' color='primary'>DESCARGAR</DefaultButtonFake>
        </Container>
    )
}

export default ContractsSimulatorRender

const Container = styled.div`
    width: 100%;
    height: 80vh;
    overflow-y: auto;
    position: relative;
    /* background-color: #C4C4C4; */
`

const DefaultButtonFake = styled(DefaultButton)`
    position: fixed;
    bottom: 70px;
    margin-left: 12%;
`

const DefaultCardContainer = styled(DefaultCard)`
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
`
