import React from 'react'
import styled from 'styled-components'
import DefaultDialog from '../../../common/DefaultDialog'
import { Text } from '../../../common/Text'
import FormInvestment from '../home/FormInvestment'

const CardBonus = (props) => {

    const { values } = props

    return (
        <Wrapper bg={values.img}>
            <Content>
                <Justify>
                    <Text color="#fff" fontSize="20px">Inversión</Text>
                    <Text color="#F1C40F" fontSize="24px">{values.investment}</Text>
                </Justify>
            </Content>
            <Content>
                <Justify>
                    <Text color="#fff" fontSize="20px">Bono trimestral</Text>
                    <Text color="#F1C40F" fontSize="24px">{values.gain}</Text>
                </Justify>
            </Content>
            <Content>
                <DefaultDialog component={<FormInvestment blocked={true} cash={values.value}/>} title="INVERTIR"/>
            </Content>
        </Wrapper>
    )
}

export default CardBonus

const Wrapper = styled.div`
    height: 100px;
    width: 100%;
    max-width: 588px;
    background-color: #212936;
    background-image: url(${p => p.bg});
    border-radius: 4px;
    background-size: contain;
    background-repeat: no-repeat;
    border: 1px solid #313C4E;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
`

const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const Justify = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 5px;
`