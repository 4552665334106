import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { authActions } from '../../../../redux/actions'
import { updateImage } from '../../../../redux/api/auth'
import { DefaultButton } from '../../../common/DefaultButton'
import DefaultFile from '../../../common/DefaultFile'
import { Text } from '../../../common/Text'

const UpdatePhoto = () => {

    const [file, setFile] = useState(null)

    const dispatch = useDispatch()

    const handleB64File = file => {
        setFile(file)
    }

    const handleSubmit = async () => {
        const form = new FormData()
        form.append('file', file)
        const request = await updateImage(form)
        if (request.error) {
            
        }
        else{
            dispatch(authActions.changeDataUser({image: request.Location}));
            setTimeout(() => {
                window.location.reload()
            }, 500);
        }
    }

    return (
        <Container>
            <Text color='#f1c40f'>CAMBIAR FOTO DE PERFIL</Text>
            <DefaultFile title="Elegir imagen" handleB64File={handleB64File}/>
            <Footer>
                <DefaultButton onClick={handleSubmit} disabled={!file ? true : false} color='primary' variant="contained">ACTUALIZAR FOTO</DefaultButton>
            </Footer>
        </Container>
    )
}

export default UpdatePhoto

const Container = styled.div`
    padding: 15px;
    display: grid;
    gap: 25px;
`

const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
`