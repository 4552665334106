import React from 'react'
import styled from 'styled-components'
import useInvestments from '../../../hooks/useInvestments'
import DefaultDialog from '../../common/DefaultDialog'
import DefaultTable from '../../common/DefaultTable'
import ErrorPage from '../../common/ErrorPage'
import PageLoading from '../../common/PageLoading'
import { Text } from '../../common/Text'
import FormInvestment from '../../ui/organisms/home/FormInvestment'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { IconButton } from '@material-ui/core'
import ReplayIcon from '@material-ui/icons/Replay';

const ListInvestments = () => {

    const columns = [
        { id: "id", label: "" },
        { id: "dateFrom", label: "Fecha" },
        { id: "dateTo", label: "Vence" },
        { id: "amount", label: "Monto" },
        { id: "current_profit", label: "Ganancia" },
        { id: "acciones", label: "" },
    ]

    const { investments, date } = useInvestments('vigentes')

    const tableRender = () => {
        if (investments) {
            const result = investments.map((item, index) => ({
                ...item,
                id: <Id>{item?.term / 30}</Id>,
                amount: `$${item.amount}`,
                dateFrom: new Date(item.dateFrom).toLocaleDateString(),
                dateTo: new Date(item.dateTo).toLocaleDateString(),
                current_profit: `$${item.current_profit?.toFixed(2)}`,
                acciones: <div>
                    <IconButton disabled={date} title="Reinvertir dinero" color='primary'><ReplayIcon/></IconButton>
                    <IconButton disabled={date} title="Retirar dinero" color='primary'><AttachMoneyIcon/></IconButton>
                </div>
            }))
            return result
        }
    }

    if (investments === null) {
        return <PageLoading h="100%"/>
    }

    if (investments === false) {
        return <ErrorPage/>
    }

    return (
        <Container>
            <Header>
                <Text color="#FFFFFF" fontSize="20px">Inversiones</Text>
                <DefaultDialog
                    title="AÑADIR PAQUETE"
                    component={<FormInvestment />}
                />
                {/* <DefaultButton variant='contained' color='primary'>AÑADIR PAQUETE</DefaultButton> */}
            </Header>
            <Scroll>
                <DefaultTable rows={tableRender()} columns={columns} pageSize="10" footer={false} />
            </Scroll>
        </Container>
    )
}

export default ListInvestments

const Container = styled.div`
    display: grid;
    gap: 1rem;
`

const Scroll = styled.div`
    overflow-x: auto;
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
`

const Id = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #B7950B;
    color: #212936;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
`