import React from 'react'
import styled from 'styled-components'
import Item from './item/Item'
// import BookmarkIcon from '@material-ui/icons/Bookmark';
import HomeIcon from '@material-ui/icons/Home';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import BarChartIcon from '@material-ui/icons/BarChart';
import BookIcon from '@material-ui/icons/Book';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import { Img } from '../../common/Image';
import logo from "../../../assets/images/logo_home.png";
import { useViewPort } from '../../../hooks/useViewPort';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const Aside = (props) => {

    const { menu, handleMenu } = props

    const { width } = useViewPort()

    return (
        <AsideWrapper menu={menu}>
            <LogoWrapper>
                <Img src={logo} width="145px"/>
                {width <= 768 && <IconButton onClick={() => handleMenu(false)} color="primary" aria-label="upload picture" component="span">
                    <CloseIcon/>
                </IconButton>}
            </LogoWrapper>
            <ListItem>
                <Item to="/home" title="Inicio" icon={<HomeIcon />} />
                <Item to="/profile" title="Mi perfil" icon={<AccountCircleIcon />} />
                <Item to="/investments" title="Inversiones" icon={<BarChartIcon />} />
                <Item to="/contracts" title="Contratos" icon={<BookIcon />} />
                <Item to="/bonus" title="Bono trimestral" icon={<ReceiptIcon />} />
                <Item to="/frequent-questions" title="Preguntas frecuentes" icon={<ContactSupportIcon />} />
            </ListItem>
            <Logout>
                <Item to="/auth/logout" title="Cerrar sesión" icon={<LogoutIcon />} />
            </Logout>
        </AsideWrapper>
    )
}

export default Aside

const AsideWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: calc(256px - 2rem);
    height: 100vh;
    background-color: #212936;
    border-right: 2px solid #313c4e;
    padding: 1rem;
    @media(max-width: 768px) {
        transition: ease-in-out 0.3s left;
        left: ${p => !p.menu ? 'calc(-256px - 2rem)' : '0'};
        z-index: 999;
    }
`

const LogoWrapper = styled.div`
    width: calc(100% - 2rem);
    height: 40px;
    /* background-color: #F1C40F; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    color: white;
    font-weight: 600;
`

const ListItem = styled.div`
    height: calc(100vh - 40px - 3rem - 70px);
    margin: 1.5rem 0;
    display: flex;
    flex-direction: column;
    gap: .5rem;
`

const Logout = styled.div`
    height: 70px;
    display: flex;
    flex-direction: column;
    gap: .5rem;
`