import React from 'react'
import styled from 'styled-components'
import DefaultTable from '../../common/DefaultTable'
import ErrorPage from '../../common/ErrorPage'
import PageLoading from '../../common/PageLoading'
import { Text } from '../../common/Text'
// import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
// import { IconButton } from '@material-ui/core'
// import ReplayIcon from '@material-ui/icons/Replay';
import useContracts from '../../../hooks/useContracts'

const ListClients = () => {

    const columns = [
        { id: "id", label: "" },
        { id: "dateFrom", label: "Nombre y apellido" },
        { id: "dateTo", label: "Fecha" },
        { id: "amount", label: "Inversion" },
        { id: "current_profit", label: "Comision" },
    ]

    const { contracts } = useContracts('vigentes')

    const tableRender = () => {
        if (contracts) {
            const result = contracts.map((item, index) => ({
                ...item,
                id: <Id>{item?.term / 30}</Id>,
                amount: `$${item.amount}`,
                dateFrom: new Date(item.dateFrom).toLocaleDateString(),
                dateTo: new Date(item.dateTo).toLocaleDateString(),
                current_profit: `$${item.current_profit?.toFixed(2)}`,
            }))
            return result
        }
    }

    if (contracts === null) {
        return <PageLoading h="100%"/>
    }

    if (contracts === false) {
        return <ErrorPage/>
    }

    return (
        <Container>
            <Header>
                <Text color="#FFFFFF" fontSize="20px">Cartera de clientes</Text>
                {/* <DefaultButton variant='contained' color='primary'>AÑADIR PAQUETE</DefaultButton> */}
            </Header>
            <Scroll>
                <DefaultTable rows={tableRender()} columns={columns} pageSize="10" footer={false} />
            </Scroll>
        </Container>
    )
}

export default ListClients

const Container = styled.div`
    display: grid;
    gap: 1rem;
`

const Scroll = styled.div`
    overflow-x: auto;
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
`

const Id = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #B7950B;
    color: #212936;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
`