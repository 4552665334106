import { Card } from "@material-ui/core";
import styled from "styled-components";

export const DefaultCard = styled(Card)`
    background: ${p => p.bg || '#212936'};
    border: 1px solid ${p => p.border || '#313C4E'};
    box-sizing: border-box;
    border-radius: 4px;
    color: ${p => p.color || '#AFBDD1'};
    padding: 1rem 2rem;
    width: 100%;
    grid-area: ${p => p.area || false};
    max-height: ${p => p.h || false};
    max-width: ${p => p.w || false};
    /* align-self: center;
    justify-self: center; */
`