import React from 'react'
import styled from 'styled-components'
import DefaultSearch from '../../../common/DefaultSearch'
import DefaultTable from '../../../common/DefaultTable'
import { Text } from '../../../common/Text'

const ListAdviser = () => {

    const columns = [
        // { id: "id", label: "" },
        { id: "symbol", label: "Nombre y apellido" },
        { id: "value", label: "Alta" },
        { id: "current_price", label: "" },
    ]

    return (
        <ListAdviserWrapper>
            <Text color="white" fontSize="20px">Cartera de asesores</Text>
            <DefaultSearch />
            <DefaultTable pageSize={10} columns={columns} rows={[]} />
        </ListAdviserWrapper>
    )
}

export default ListAdviser

const ListAdviserWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    div{
        max-width: 100%;
    }
`