import React from 'react'
import { DefaultCard } from '../../../components/common/DefaultCard'
import DefaultTable from '../../../components/common/DefaultTable'
import { Text } from '../../../components/common/Text'

const Withdrawals = () => {

    const columns = [
        { id: "dateFrom", label: "Nombre y apellido" },
        { id: "monto", label: "Monto" },
        { id: "dateTo", label: "Medio de pago" },
        { id: "amount", label: "Info de pago" },
        { id: "current_profit", label: "Fecha" },
        { id: "current_profit", label: "Pagado" },
    ]

    const tableRender = (contracts) => {
        if (contracts) {
            // const result = contracts.map((item, index) => ({
            //     ...item,
            //     id: <Id>{item?.term / 30}</Id>,
            //     amount: `$${item.amount}`,
            //     dateFrom: new Date(item.dateFrom).toLocaleDateString(),
            //     dateTo: new Date(item.dateTo).toLocaleDateString(),
            //     current_profit: `$${item.current_profit?.toFixed(2)}`,
            // }))
            // return result
        }
    }

    return (
        <div>
            <DefaultCard>
                <Text color='#F1C40F' fontWeight="600" fontSize="20px">MAYO 2022 - TOTAL: $584.360,00</Text>
                <DefaultTable rows={[]} columns={columns} pageSize={10} />
            </DefaultCard>
        </div>
    )
}

export default Withdrawals