import { IconButton } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { DefaultButton } from '../../common/DefaultButton'
import { Divider } from '../../common/Divider'
import { Text } from '../../common/Text'
import CloseIcon from '@material-ui/icons/Close';
import { Form, Formik } from 'formik'
import DefaultField from '../../common/DefaultField'
import { updateProfileSchema } from '../../schemas/auth'
import DefaultSelect from '../../common/DefaultSelect'
import { updateProfile } from '../../../redux/api/auth'
import { alertsActions, authActions } from '../../../redux/actions'

const EditProfile = (props) => {

    const { handleEditProfile, states } = props

    const [loading, setLoading] = useState(false)

    const { user } = useSelector(state => state.auth)

    const dispatch = useDispatch()

    // FORMIK
    const initialValues = {
        name: user.name,
        lastName: user.lastName,
        dni: user.dni,
        email: user.email,
        adress: user.adress,
        state: user?.state?.id,
        phone: user.phone,
        birthdate: user.birthdate,
        wallet: user.wallet,
        cbu: user.cbu
    };

    const handleSubmit = async (values) => {
        setLoading(true)
        const request = await updateProfile(values, user.id)
        setLoading(false)
        if (request.error) {
            dispatch(alertsActions.showSnack({
                variant: 'error',
                message: '¡Ocurrió un error al actualizar el perfil!'
            }))
        }
        else {
            dispatch(authActions.changeDataUser(values));
            dispatch(alertsActions.showSnack({
                variant: 'success',
                message: '¡Perfil actualizado!'
            }))
        }
    }

    return (
        <Container>
            <TextFake color="#fff" fontSize="20px">
                <div>Datos personales</div>
                <IconButton onClick={handleEditProfile} color="primary">
                    <CloseIcon />
                </IconButton>
            </TextFake>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={updateProfileSchema}
            >
                {() => (
                    <FormContainer>
                        {/** Password */}
                        <Data columns="300px auto">
                            <Text fontSize="14px">Nombre y Apellido</Text>
                            <DefaultField
                                name="name"
                                type="text"
                                label="Nombre"
                                variant='outlined'
                                required
                            />
                            <Text fontSize="14px">Apellido</Text>
                            <DefaultField
                                name="lastName"
                                type="text"
                                label="Apellido"
                                variant='outlined'
                                required
                            />
                            <Text fontSize="14px">DNI</Text>
                            <DefaultField
                                name="dni"
                                type="text"
                                label="Documento"
                                variant='outlined'
                                required
                            />
                            <Text fontSize="14px">Dirección</Text>
                            <DefaultField
                                name="adress"
                                type="text"
                                label="Direccion"
                                variant='outlined'
                                required
                            />
                            <Text fontSize="14px">Provincia</Text>
                            {states ? <DefaultSelect
                                name="state"
                                type="text"
                                variant='outlined'
                                required
                                options={states}
                            /> : <DefaultSelect
                                type="text"
                                variant='outlined'
                                disabled
                                options={[]}
                            />
                            }
                            <Text fontSize="14px">Fecha de nacimiento</Text>
                            <DefaultField
                                name="birthdate"
                                type="date"
                                variant='outlined'
                                required
                            />
                        </Data>
                        <Divider />
                        <Text color="#fff" fontSize="20px">Inicio de sesión</Text>
                        <Data columns="300px auto">
                            <Text fontSize="14px"> Mail</Text>
                            <DefaultField
                                name="email"
                                type="text"
                                variant='outlined'
                                disabled
                            />
                            <Text fontSize="14px">Contraseña</Text>
                            <DefaultButton color="primary" size='small'>Cambiar contraseña</DefaultButton>
                        </Data>
                        <Divider />
                        <Text color="#fff" fontSize="20px">Métodos de pago</Text>
                        <Data columns="300px auto">
                            <Text fontSize="14px">Transferencia (CBU)</Text>
                            <DefaultField
                                name="cbu"
                                type="text"
                                label="Transferencia"
                                variant='outlined'
                            />
                            <Text fontSize="14px">Wallet</Text>
                            <DefaultField
                                name="wallet"
                                type="text"
                                label="Etiqueta"
                                variant='outlined'
                            />
                        </Data>
                        {!loading ? (
                            <DefaultButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                id="submit"
                            >
                                GUARDAR CAMBIOS
                            </DefaultButton>
                        )
                            :
                            (
                                <DefaultButton
                                    variant="contained"
                                    color="primary"
                                    id="submit"
                                    disabled
                                >
                                    ACTUALIZANDO
                                </DefaultButton>
                            )}

                    </FormContainer>
                )}
            </Formik>

        </Container>
    )
}

export default EditProfile

const Container = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 15px;
`

const Data = styled.div`
    display: grid;
    row-gap: 15px;
    grid-template-columns: ${p => p.columns};
    @media(max-width: 768px){
        display: flex;
        flex-direction: column;
    }
`

const TextFake = styled(Text)`
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
`

const FormContainer = styled(Form)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    /* margin: 20px auto;
    width: 90%; */
`