import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import styled from 'styled-components'
import { DefaultButton } from '../../../common/DefaultButton'
import DefaultField from '../../../common/DefaultField'
import DefaultPhone from '../../../common/DefaultPhone'
import { Text } from '../../../common/Text'
import { createAdviser } from '../../../schemas/admin'

const CreateAdviser = () => {

    const [loading, setLoading] = useState(false)

    // FORMIK
    const initialValues = {
        name: '',
        email: '',
        password: '',
        phone: '',
    };

    const handleSubmit = () => {

    }

    return (
        <div>
            <Text color="white" fontSize="20px">Alta asesor</Text>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={createAdviser}
            >
                {({ values, setFieldValue }) => (
                    <FormContainer>
                        {/* NAME */}
                        <DefaultFieldFake
                            name="name"
                            type="email"
                            label="Nombre y Apellido"
                            variant='outlined'
                            required
                        />
                        {/** EMAIL */}
                        <DefaultFieldFake
                            name="email"
                            type="email"
                            label="Mail"
                            variant='outlined'
                            required
                        />
                        {/** Password */}
                        <DefaultFieldFake
                            name="password"
                            type={'text'}
                            label="Contraseña"
                            required
                            variant='outlined'
                        />
                        {/* Phone */}
                        <DefaultPhoneFake
                                name="phone"
                                label="Teléfono celular"
                                value={values.phone}
                                setFieldValue={setFieldValue}
                            />
                        {!loading ? (
                            <DefaultButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                id="submit"
                            >
                                INICIAR SESIÓN
                            </DefaultButton>
                        ) : (
                            <DefaultButton
                                variant="contained"
                                color="primary"
                                id="submit"
                                disabled
                            >
                                CARGANDO...
                            </DefaultButton>
                        )}


                    </FormContainer>
                )}
            </Formik>
        </div>
    )
}

export default CreateAdviser

const FormWrapper = styled.div`
    /* display: flex;
    justify-content: center; */
`

const FormContainer = styled(Form)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin: 20px auto;
    width: 90%;
    #submit{
        margin: 0 auto;
        width: 150px;
        margin-top: 20px;
    }
`

const DefaultFieldFake = styled(DefaultField)`
    max-width: 100%;
`

const DefaultPhoneFake = styled(DefaultPhone)`
    max-width: 100%;
    width: 100%;
    .form-control{
        background-color: #212936;
    }
    .selected-flag{
        background-color: #212936;
    }
`