import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const Item = (props) => {

    const { to = false, title = "Menu", icon = false } = props

    const history = useHistory()

    const isActive = to === window.location.pathname

    return (
        <ItemWrapper onClick={() => history.push(to)} active={!!isActive}>
            <Icon>
                {icon}
            </Icon>
            {title}
        </ItemWrapper>
    )
}

export default Item

const ItemWrapper = styled.div`
    background: ${p => p.active && '#2B3648'};
    border-radius: 4px;
    height: 40px;
    cursor: pointer;
    color: ${p => p.active && '#F1C40F'};
    font-weight: 500;
    display: flex;
    align-items: center;
    transition: ease-in-out .3s all;
    :hover{
        background-color: ${p => !p.active && '#2B3648'};
    }
`

const Icon = styled.div`
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
`