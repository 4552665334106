import React, { useState } from 'react'
import styled from 'styled-components'
import { DefaultButton } from '../../../common/DefaultButton'
import { Text } from '../../../common/Text'

const CardCodes = (props) => {

    const { code, month } = props

    const [button, setButton] = useState('Copiar código')

    const handleCopy = () => {
        navigator.clipboard.writeText(
            code
        );
        setButton('Copiado')
        setTimeout(() => {
            setButton('Copiar código')
        }, 2000);
    }

    return (
        <Wrapper>
            <Text color="#fff" fontSize="20px">Código asesor {month} meses</Text>
            <Text color="#F1C40F" fontSize="24px" fontWeight="600">{code || 'No data'}</Text>
            <Content>
                {/* <DefaultDialog component={<FormInvestment blocked={true} cash={values.value}/>} title="Compartir"/> */}
                <DefaultButton onClick={handleCopy} variant='contained' color='primary'>{button}</DefaultButton>
            </Content>
        </Wrapper>
    )
}

export default CardCodes

const Wrapper = styled.div`
    height: 156px;
    width: 100%;
    padding: 15px 0;
    max-width: 436px;
    background-color: #212936;
    border-radius: 4px;
    border: 1px solid #313C4E;
    gap: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const Content = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`