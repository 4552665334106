import React from 'react'
import styled from 'styled-components'
import { Img } from '../../common/Image'
import logo from "../../../assets/images/logo_login.png";
import { Text } from '../../common/Text';
// import { DefaultButton } from '../../common/DefaultButton';
// import FormLogin from '../../ui/organisms/auth/FormLogin';
// import { useHistory } from 'react-router-dom';
import FormAdmin from '../../ui/organisms/auth/FormAdmin';

const AdminForm = () => {

    // const history = useHistory()

    // const [isVisible, setIsVisible] = useState(true)

    // const handleAdviser = (state) => {
    //     setIsVisible(state)
    // }

    return (
        <AuthFormWrapper>
            <Img w="262px" src={logo}/>
            <Text fontSize="24px" fontWeight="500">Bienvenido/a Administrador!</Text>
            <FormAdmin />
            {/* {isVisible && <Footer>
                <Text>¿Aún no tienes cuenta?</Text>
                <DefaultButton color='primary' onClick={() => history.push('../auth/signup')}>REGISTRARME</DefaultButton>
            </Footer>} */}
        </AuthFormWrapper>
    )
}

export default AdminForm

const AuthFormWrapper = styled.div`
    padding: 0 20px;
    width: calc(100% - 40px);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 25px;
`

const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
`