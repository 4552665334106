import React, { useState } from 'react'
import TabsComponent from '../../../common/TabsComponent'
import InversorForm from '../../molecules/auth/InversorForm';

const FormLogin = (props) => {

    const { handleAdviser } = props

    const tabs = [
        { label: `SOY INVERSOR`, id: 0 },
        { label: `SOY ASESOR`, id: 1 },
    ];

    const [tab, setTab] = useState(0);

    const handleTab = (e, value) => {
        if (value === 0) {
            handleAdviser(true)
        }
        else{
            handleAdviser(false)
        }
        setTab(value);
    };

    return (
        <div>
            <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
            {tab === 0 && <InversorForm rol="investor" />}
            {tab === 1 && <InversorForm rol="adviser" />}
        </div>
    )
}

export default FormLogin