import React from "react";
import { createTheme, StylesProvider, ThemeProvider } from "@material-ui/core";

export function MaterialThemeProvider(props) {
	const theme = createTheme({
		typography: {
			fontFamily: ["Roboto"].join(","),
			fontSize: 14,
			button: {
				textTransform: "none",
				// fontWeight: "bold",
			},
		},
		palette: {
			primary: {
				// light: will be calculated from palette.primary.main,
				main: "#F1C40F",
				// dark: will be calculated from palette.primary.main,
				contrastText: "#212936" //will be calculated to contrast with palette.primary.main
			},
			secondary: {
				// light: will be calculated from palette.primary.main,
				main: "#212936",
				// dark: will be calculated from palette.primary.main,
				// contrastText: "#fff" //will be calculated to contrast with palette.primary.main
			},
			muted: {
				main: "#313C4E",
			},
			error: {
				// light: will be calculated from palette.primary.main,
				main: "#D24335",
				// dark: will be calculated from palette.primary.main,
				// contrastText: "#fff" //will be calculated to contrast with palette.primary.main
			},
			success: {
				main: "#58D68D",
			}
		},
		props: {
			// Name of the component ⚛️
			MuiButtonBase: {
				// The properties to apply
				disableRipple: false, // No more ripple, on the whole application 💣!
			},

			// Set default elevation to 1 for popovers.
			MuiPopover: {
				elevation: 1,
			},
		},
	});
	const { children } = props;

	return (
		<StylesProvider injectFirst>
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</StylesProvider>
	);
}
