import React from 'react'
import styled from 'styled-components'
import ImageBanner from '../../components/templates/auth/ImageBanner'
// import AuthForm from '../../components/templates/auth/AuthForm'
import AdminForm from '../../components/templates/auth/AdminForm'

const Admin = () => {
    return (
        <AdminWrapper>
            <ImageBanner/>
            <AdminForm/>
        </AdminWrapper>
    )
}

export default Admin

const AdminWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media(max-width: 768px){
        display: flex;
        flex-direction: column;
    }
`