import React from 'react'
import styled from 'styled-components'
import { DefaultCard } from '../../../components/common/DefaultCard'
import CreateAdviser from '../../../components/ui/organisms/adviser/CreateAdviser'
import ListAdviser from '../../../components/ui/organisms/adviser/ListAdviser'

const AdviserAdmin = () => {
    return (
        <AdviserWrapper>
            <DefaultCard>
                <ListAdviser/>
            </DefaultCard>
            <DefaultCard>
                <CreateAdviser/>
            </DefaultCard>
        </AdviserWrapper>
    )
}

export default AdviserAdmin

const AdviserWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
`