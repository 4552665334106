import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import React, { useState } from 'react'
import styled from 'styled-components'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Text } from '../../common/Text';

const QuestionsTemplate = () => {

    const QUESTIONS = [
        {
            question: "¿En qué fechas puedo realizar interés compuesto?",
            answers: "Se puede realizar interés compuesto los días 1,2 y 3 de cada mes. Para ello se debe seleccionar manualmente, dentro de esas fechas, el botón “reinvertir” en cada paquete. Esta opción figura en la sección inicio y en la sección inversiones."
        },
        {
            question: "¿Desde qué monto puedo realizar interés compuesto?",
            answers: "Se puede hacer interés compuesto del saldo disponible que figure en ganancias totales."
        },
        {
            question: "¿Qué días puedo solicitar mi retiro de ganancias?",
            answers: "Los retiros se solicitan los días 1,2 y 3 cada mes. En caso de no hacer interés compuesto, la orden de retiro se emite automáticamente."
        },
        {
            question: "¿Cuándo se procesan los retiros?",
            answers: "Los retiros se procesan desde el día que se realiza la solicitud hasta el 10 de cada mes."
        },
        {
            question: "¿Dónde puedo obtener mi contrato?",
            answers: "Los contratos se pueden visualizar y descargar digitalmente en la sección contratos, o se pueden retirar físicamente por cualquiera de nuestras oficinas."
        },
        {
            question: "¿Puedo ingresar más capital en cualquier momento?",
            answers: "Sí, se puede ingresar capital en cualquier momento. Por cada inversión se realiza un nuevo contrato."
        },
        {
            question: "¿Cuál es el monto mínimo de inversión?",
            answers: "El monto mínimo de inversión para un nuevo contrato es de USD 300."
        },
        {
            question: "¿Puedo tener más de un contrato?",
            answers: "Sí, se puede tener más de un contrato a la vez."
        },
        {
            question: "¿Qué hacer si necesito soporte?",
            answers: "Se puede contactar al equipo de soporte mediante el chatbot que aparece en la esquina inferior derecha de la pantalla. Un operador se encarga de resolver las consultas de lunes a viernes dentro de nuestros horarios de atención (9:00 a 21:00hs, hora de Argentina)."
        },
        {
            question: "¿Puedo retirar mi capital cuando quiera?",
            answers: "Sí, se puede retirar el capital antes de la finalización del contrato, tiene la penalidad de los dividendos obtenidos. En el contrato de 12 meses se puede retirar a partir de los 6 meses con la misma penalidad."
        },
    ]

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Container>
            {QUESTIONS.map((item, index) => (
                <div>
                <AccordionFake expanded={expanded === index} onChange={handleChange(index)}>
                    <AccordionSummaryFake
                        isOpen={expanded === index ? true : false}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Text fontSize="15px" color={expanded === index ? '#F1C40F' : '#fff'}>{item.question}</Text>
                    </AccordionSummaryFake>
                    <AccordionDetailsFake isOpen={expanded === index ? true : false}>
                        <TextFake fontSize="14px" color={'#fff'}>{item.answers}</TextFake>
                    </AccordionDetailsFake>
                </AccordionFake>
                </div>
            ))}
        </Container>
    )
}

export default QuestionsTemplate

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
`

const AccordionFake = styled(Accordion)`
    border-radius: 4px;
    background: #212936;
`

const AccordionSummaryFake = styled(AccordionSummary)`
    background: #212936;
    border-radius: 4px;
    border: 1px solid #313C4E;
    border-bottom: ${p => p.isOpen && '0px'};
    border-bottom-right-radius: ${p => p.isOpen && '0px'};
    border-bottom-left-radius: ${p => p.isOpen && '0px'};
    box-shadow: none;
    padding: 0 24px;
    svg{
        color: ${p => p.isOpen ? '#F1C40F' : '#fff'};
    }
`

const AccordionDetailsFake = styled(AccordionDetails)`
    background: #212936;
    border: 1px solid #313C4E;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 24px;
    padding-top: 0;
`

const TextFake = styled(Text)`
    line-height: 18px;
`