import React from 'react'
import styled from 'styled-components';
import { DefaultCard } from '../../components/common/DefaultCard';
import CashIverted from '../../components/templates/home/CashIverted';
import ListInvestments from '../../components/templates/home/ListInvestments';
import Quotes from '../../components/templates/home/Quotes';

const Home = () => {

    return (
        <Wrapper>
            <DefaultCard bg="#F1C40F" border="#B7950B" color="#212936" area="cash" h="268px">
                <CashIverted/>
            </DefaultCard>
            <DefaultCard area="cripto" h="340px">
                <Quotes/>
            </DefaultCard>
            <DefaultCard area="list" h="100%">
                <ListInvestments/>
            </DefaultCard>
        </Wrapper>
    )
}

export default Home

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 268px auto;
    grid-template-areas:"cash   cash   list list list list"
                        "cripto cripto list list list list";
    gap: 1rem;
    @media(max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`