import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Span, Text } from '../../../common/Text'
import { Img } from '../../../common/Image'
import header from "../../../../assets/images/header_contrato.png";
import footer from "../../../../assets/images/pie_contrato.png";
import tabla from "../../../../assets/images/tabla.png";
// import { DefaultButton } from '../../../common/DefaultButton'

const SimulatorPdf = (props) => {

    const { values } = props

    const dateTo = new Date();
    let dateFrom = new Date().setMonth(dateTo.getMonth() + values.package)
    dateFrom = new Date(dateFrom)
    console.log(dateFrom);

    let casesProfit = {
        "3": 0.04,
        "6": 0.06,
        "12": 0.08
    }
    let amountMonth = (values.cash * casesProfit[String(values.package)]) / values.package
    amountMonth = amountMonth.toFixed(2)
    const countMonth = values.package
    let amountTotal = amountMonth * countMonth
    amountTotal = amountTotal.toFixed(2)
    const listContracts = Array.from(Array(countMonth).keys())
    const profit = casesProfit[String(values.package)] * 100

    const fourTerm = {
        '3': 'En caso de que el mutuante quisiera rescindir este contrato, solo podrá hacerlo si cobró su primer interés mensual. Esto se rescindirá sin ninguna penalidad y se entregará el capital en 10 días hábiles.',
        '6': 'No hay opción de rescición',
        '12': 'En caso de que el mutuante quisiera rescindir este contrato antes del sexto mes, se aplicará una multa sobre los últimos tres meses de intereses compensatorios los cuales no serán cobrados y descontados sobre el capital inicial otorgado, después del sexto mes no tendrán penalidad alguna para retirar su capital inicial, en ambos casos deberá el mutuante esperar 10 días hábiles a partir del aviso de rescisión para la integración del capital aportado, durante este plazo no correrán intereses compensatorios a favor del mutuante.'
    }

    const { user } = useSelector(state => state.auth)

    return (
        <Wrapper id="contrato">
            <Img src={header} h="20px" />
            <Title fontSize="14px" textAlign="center" color="#000">CONTRATO DE MUTUO (no válido)</Title>
            <Text fontSize="9px" textAlign="justify" color="#000">Entre el/la señor/a <TextSub>{values?.name} {values?.lastName}</TextSub> con Documento Nacional de Identidad N° <TextSub>{values?.dni}</TextSub> con domicilio en calle <TextSub>{values.adress}</TextSub> provincia de <TextSub>{values?.state}</TextSub> , Argentina,
                número de celular <TextSub>{values?.phone}</TextSub> y e-mail <TextSub>{values?.email}</TextSub> en adelante denominado el mutuante, por una parte, y por la otra el/la señor/a <TextSub>{user?.name} {user?.lastName}</TextSub> con Documento Nacional de Identidad N° <TextSub>{user?.dni}</TextSub> con domicilio en calle <TextSub>{user?.adress}</TextSub> provincia de <TextSub>{user?.state?.name}</TextSub> , Argentina, número de celular <TextSub>{user?.phone}</TextSub> y e-mail <TextSub>{user?.email}</TextSub> en adelante denominado el mutuario, convienen en celebrar el presente contrato de mutuo sujeto a las siguientes cláusulas:
            </Text>

            <SubTitle fontSize="11px" color="#000">PRIMERA: DEPÓSITO</SubTitle>
            <Text fontSize="9px" textAlign="justify" color="#000">El mutuante da en mutuo al mutuario, quien lo acepta, la suma de (USD <TextSub>{values.cash}</TextSub>), que el mutuario recibe este monto en efectivo en este acto.</Text>


            <SubTitle fontSize="11px" color="#000">SEGUNDA: PLAZO DEVOLUCIÓN CAPITAL INICIAL</SubTitle>
            <Text fontSize="9px" textAlign="justify" color="#000">El mutuario deberá devolver la suma de (USD <TextSub>{amountTotal}</TextSub>),
                que serán restituidos a los {countMonth} meses desde la firma del presente documento.
            </Text>

            <SubTitle fontSize="11px" color="#000"> <SubTitle fontSize="11px" color="#000">TERCERA: TASA DE RETORNO</SubTitle></SubTitle>
            <Text fontSize="9px" textAlign="justify" color="#000">El mutuario abonará al mutuante la suma de (USD <TextSub>{amountMonth}</TextSub>),
                mensuales equivalentes al {profit}% del capital otorgado en concepto de interés compensatorio, que serán abonados del día 3 al día 10 de cada mes, durante {countMonth} meses por medio de retiro que solicite el mutuante. Los pagos del interés compensatorio serán estipulados en el siguiente cronograma de pagos:
            </Text>

            <ul>
                {listContracts.map(item => <li>
                    <Text fontSize="9px" color="#000">3 al 10 de <TextSub>{ }</TextSub>
                        La suma de (USD <TextSub>{amountMonth}</TextSub>)
                    </Text>
                </li>)}
                <li>
                    <Text fontSize="9px" color="#000">El día <TextSub>{dateTo.toLocaleDateString()} </TextSub>
                        La suma de (USD <TextSub>{amountTotal}</TextSub>)
                    </Text>
                </li>
            </ul>

            <SubTitle fontSize="11px" color="#000"> <SubTitle fontSize="11px" color="#000">CUARTA: RESCISIÓN </SubTitle></SubTitle>
            <Text fontSize="9px" textAlign="justify" color="#000">{fourTerm[String(countMonth)]}
            </Text>

            <SubTitle fontSize="11px" color="#000">QUINTA: BONOS TRIMESTRALES</SubTitle>
            <br />
            <Img src={tabla} h="60px" />

            <SubTitle fontSize="11px" color="#000"> <SubTitle fontSize="11px" color="#000">SEXTA: CONTIENDA JUDICIAL</SubTitle></SubTitle>
            <Text fontSize="9px" textAlign="justify" color="#000">Las partes constituyen domicilios en los indicados al comienzo donde serán válidas las notificaciones que se cursen.   En el caso de contienda judicial, se someten a los tribunales de la primera Circunscripción Judicial de la Provincia de Mendoza.
            </Text>

            <SubTitle fontSize="11px" color="#000"> <SubTitle fontSize="11px" color="#000">SÉPTIMA: VIGENCIA</SubTitle></SubTitle>
            <Text fontSize="9px" textAlign="justify" color="#000">El presente contrato tiene una vigencia de 3 meses a partir del <TextSub>{dateFrom.toLocaleDateString()}</TextSub>,
                hasta el <TextSub>{dateTo.toLocaleDateString()}</TextSub>.
            </Text>


            <SubTitle fontSize="11px" color="#000"> <SubTitle fontSize="11px" color="#000">OCTAVA: TIPO DE INTERESES
            </SubTitle></SubTitle>
            <Text fontSize="9px" textAlign="justify" color="#000">Solamente correrán para este contrato y sus efectos, intereses compensatorios.
            </Text>

            <SubTitle fontSize="11px" color="#000">NOVENA: TITULAR DE COBRO</SubTitle>
            <Text fontSize="9px" textAlign="justify" color="#000">El pago de los intereses de la cláusula tercera, deberá ser recibido a nombre del firmante de dicho contrato o apoderado que habilite expresamente vía e-mail al mutuario.
            </Text>


            <SubTitle fontSize="11px" color="#000">DÉCIMA: RIESGOS</SubTitle>
            <Text fontSize="9px" textAlign="justify" color="#000">El más alto nivel de seguridad de los recursos de los operadores está garantizado por el estricto cumplimiento de un conjunto de medidas:
            </Text>
            <Text fontSize="9px" textAlign="justify" color="#000">Los recursos de la plataforma asignados a las operaciones en el mercado de contrato de inversión están bajo el control total y directo del Departamento de Gestión de Riesgos. Los recursos del fondo privado mutuo de inversión se depositan en las billeteras de las bolsas y en las billeteras de almacenamiento en frío de firmas múltiples.
            </Text>
            <Text fontSize="9px" textAlign="justify" color="#000">El Departamento de Cumplimiento y Seguridad protege los recursos que los mutuantes dan al mutuario de cualquier fraude, espionaje industrial y otros tipos de amenazas o ataques electrónicos o físicos.
            </Text>
            <Text fontSize="9px" textAlign="justify" color="#000">El Departamento de Cumplimiento y Seguridad protege los recursos que los mutuantes dan al mutuario de cualquier fraude, espionaje industrial y otros tipos de amenazas o ataques electrónicos o físicos.
            </Text>
            <Text fontSize="9px" textAlign="justify" color="#000">El riesgo es una parte inseparable de cualquier proceso empresarial y, aunque está operando dentro de estrictas directrices dirigidas a gestionar y minimizar los posibles riesgos, los mutuantes deben ser conscientes de todos los riesgos existentes:
            </Text>
            <ul>
                <li><Text fontSize="9px" textAlign="justify" color="#000">Riesgo inherente</Text></li>
                <li><Text fontSize="9px" textAlign="justify" color="#000">El riesgo de acceso no autorizado a los recursos por parte de terceros (hackeo).</Text></li>
                <li><Text fontSize="9px" textAlign="justify" color="#000">En caso de desaparición del broker  (medio donde se invierte el capital).</Text></li>
                <li><Text fontSize="9px" textAlign="justify" color="#000">El riesgo de una regulación más estricta del gobierno nacional esto provocaría la devolución de capital.</Text></li>
            </ul>

            <SubTitle fontSize="11px" color="#000">ONCEAVA: RECURSOS</SubTitle>
            <Text fontSize="9px" textAlign="justify" color="#000">Los recursos que el mutuante da al mutuario son invertidos en el mercado de forex e índices, esta compraventa se realiza a través de criptomonedas. Las ganancias obtenidas en la diferencia de esta compraventa es por la cual el mutuante recibe intereses compensatorios.</Text>

            <SubTitle fontSize="11px" color="#000">DOCEAVA: ACLARACIÓN DE CONVERSIÓN</SubTitle>
            <Text fontSize="9px" textAlign="justify" color="#000">El dólar criptográfico (USDT) tiene la misma cotización que el dólar americano por ende todo cliente que entregue sus USDT podrá realizar la conversión de dinero por cuenta propia o con ayuda de nuestros asesores financieros a dólar físico o digital americano o viceversa.</Text>
            <Text fontSize="9px" textAlign="justify" color="#000">En prueba de conformidad se firman dos ejemplares, de un mismo tenor y a un solo efecto, en la Provincia de Mendoza el día <TextSub>{dateTo.toLocaleDateString()}</TextSub>.</Text>

            <Footer>
                <Img src={footer} h="40px" />
                <Sign>
                    <Text id="sign">{values?.name}  {values?.lastName}</Text>
                </Sign>
            </Footer>
        </Wrapper>
    )
}

export default SimulatorPdf

const Wrapper = styled.div`
    padding: 20px;
    background-color: #fff;
    position: relative;
`

const TextSub = styled(Span)`
    text-decoration: underline;
`

const Title = styled(Text)`
    text-decoration: underline;
    margin: 20px 0;
`

const SubTitle = styled(Text)`
    margin-top: 15px;
`

const Footer = styled.div`
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Sign = styled.div`
    width: 150px;
    height: 1px;
    margin-top: 15px;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(172,94,0,1) 0%, rgba(241,196,15,1) 100%); 
    position: relative;
    #sign{
        position: absolute;
        top: -15px;
        font-size: 12px;
        color: #000;
        font-style: italic;
    }
`