import React from 'react'
import styled from 'styled-components'
import CardBonus from '../../ui/organisms/quarterlybonus/CardBonus'
import b1 from "../../../assets/images/b1.png";
import b2 from "../../../assets/images/b2.png";
import b3 from "../../../assets/images/b3.png";
import b4 from "../../../assets/images/b4.png";
import b5 from "../../../assets/images/b5.png";

const BonusTemplate = () => {

    const DATA = [
        {
            investment: '5.000USD',
            gain: '100USD',
            value: 5000,
            img: b1
        },
        {
            investment: '10.000USD',
            gain: '300USD',
            value: 10000,
            img: b2
        },
        {
            investment: '25.000USD',
            gain: '800USD',
            value: 25000,
            img: b3
        },
        {
            investment: '50.000USD',
            gain: '2.000USD',
            value: 50000,
            img: b4
        },
        {
            investment: '100.000USD',
            gain: '5.000USD',
            value: 100000,
            img: b5
        }
    ]

    return (
        <Container>
            {DATA.map(item => <CardBonus values={item}/>)}
        </Container>
    )
}

export default BonusTemplate

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
`