import { IconButton, InputAdornment } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DefaultButton } from '../../../common/DefaultButton'
import { Form, Formik } from 'formik';
import { loginSchema } from '../../../schemas/auth';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import DefaultField from '../../../common/DefaultField';
import { useDispatch, useSelector } from 'react-redux'
import { alertsActions, authActions } from '../../../../redux/actions';

const InversorForm = (props) => {

    const { rol } = props

    const { loading, error } = useSelector(state => state.auth)

    const dispatch = useDispatch()

    useEffect(() => {
        !!error && dispatch(alertsActions.showSnack({
            variant: 'error',
            message: error?.response?.data?.message
        }))
    }, [error])

    // FORMIK
    const initialValues = {
        email: '',
        password: ''
    };

    // STATE
    const [showPassword, setShowPassword] = useState(false);

    // FUNCTIONS
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = (values) => {
        dispatch(authActions.loginUserRequest({...values, rol}))
    };

    return (
        <FormWrapper>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={loginSchema}
            >
                {() => (
                    <FormContainer>
                        {/** EMAIL */}
                        <DefaultFieldFake
                            name="email"
                            type="email"
                            label="Mail"
                            variant='outlined'
                            required
                        />
                        {/** Password */}
                        <DefaultFieldFake
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            label="Contraseña"
                            required
                            variant='outlined'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            onClick={toggleShowPassword}
                                        >
                                            {showPassword ? (
                                                <Visibility fontSize="small" />
                                            ) : (
                                                <VisibilityOff fontSize="small" />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        {!loading ? (
                            <DefaultButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                id="submit"
                            >
                                INICIAR SESIÓN
                            </DefaultButton>
                        ) : (
                            <DefaultButton
                                variant="contained"
                                color="primary"
                                id="submit"
                                disabled
                            >
                                CARGANDO...
                            </DefaultButton>
                        )}


                    </FormContainer>
                )}
            </Formik>
        </FormWrapper>
    )
}

export default InversorForm

const FormWrapper = styled.div`
    /* display: flex;
    justify-content: center; */
`

const FormContainer = styled(Form)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin: 20px auto;
    width: 90%;
    #submit{
        margin: 0 auto;
        width: 150px;
        margin-top: 20px;
    }
`

const DefaultFieldFake = styled(DefaultField)`
    max-width: 360px;
`