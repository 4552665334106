import axios from "axios";

export async function getInvestments(status) {
    const URL = process.env.REACT_APP_API
    let endpoint = URL + '/contrats/own' 
    if (!!status) {
        endpoint += `?status=${status}`
    }
    try {
        const response = await axios.get(endpoint)
        return response.data
    } catch (error) {
        return {error}   
    }
}

export async function newContract(formData) {
    const URL = process.env.REACT_APP_API
    try {
        const response = await axios.post(URL + '/contrats', formData)
        return response.data
    } catch (error) {
        return {error}   
    }
}

export async function getContratById(id) {
    const URL = process.env.REACT_APP_API
    try {
        const response = await axios.get(URL + '/contrats/' + id)
        return response.data
    } catch (error) {
        return {error}   
    }
}