import axios from "axios";

export async function getContracts(status) {
    const URL = process.env.REACT_APP_API
    let endpoint = URL + '/contrats/ownadviser' 
    if (!!status) {
        endpoint += `?status=${status}`
    }
    try {
        const response = await axios.get(endpoint)
        return response.data
    } catch (error) {
        return {error}   
    }
}