import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Switch } from "react-router-dom"
import Commission from "./pages/admin/home/Commission";
import AdviserAdmin from "./pages/admin/adviser/Adviser";
import HomeAdmin from "./pages/admin/home/HomeAdmin";
import Withdrawals from "./pages/admin/home/Withdrawals";
import HomeAdviser from "./pages/advisor/home/HomeAdviser";
import Admin from "./pages/auth/Admin";
import Login from "./pages/auth/Login";
import Logout from "./pages/auth/Logout";
import SignUp from "./pages/auth/SignUp";
import { Clients } from "./pages/clients/Clients";
import Codes from "./pages/codes/Codes";
import Contracts from "./pages/contracts/Contracts";
import Home from "./pages/home/Home";
import { Investments } from "./pages/investments/Investments";
import Profile from "./pages/profile/Profile";
import QuarterlyBonus from "./pages/quarterlybonus/QuarterlyBonus";
import Questions from "./pages/questions/Questions";
import Simulator from "./pages/simulator/Simulator";
import PrivateRoute from "./routes/PrivateRoute";
// import Index from "./pages/public/Index";
import PublicRoute from "./routes/PublicRoute";


const Routes = () => {

    const { rol } = useSelector(state => state.auth)

    const redirect = {
		adviser: '/adviser',
		investor: '/home',
        administrator: '/admin'
	}

    return (
        <Switch>
                {/* Rutas publicas */}
                <PublicRoute path="/auth/login" exact component={Login} />
                <PublicRoute path="/auth/signup" exact component={SignUp} />
                <PublicRoute path="/auth/admin" exact component={Admin} />

                {/* Rutas privadas */}
                <PrivateRoute path="/home" exact component={Home} />
                <PrivateRoute path="/profile" exact component={Profile} />
                <PrivateRoute path="/investments" exact component={Investments} />
                <PrivateRoute path="/contracts" exact component={Contracts} />
                <PrivateRoute path="/bonus" exact component={QuarterlyBonus} />
                <PrivateRoute path="/frequent-questions" exact component={Questions} />

                <PrivateRoute path="/codes" exact component={Codes} />
                <PrivateRoute path="/adviser" exact component={HomeAdviser} />
                <PrivateRoute path="/simulator" exact component={Simulator} />
                <PrivateRoute path="/clients" exact component={Clients} />

                <PrivateRoute path="/admin" exact component={HomeAdmin} />
                <PrivateRoute path="/withdrawals" exact component={Withdrawals} />
                <PrivateRoute path="/commission" exact component={Commission} />
                <PrivateRoute path="/advisers-admin" exact component={AdviserAdmin} />

                <PrivateRoute path="/auth/logout" exact component={Logout} />

                {/* Redirecciones */}
                <Redirect path="/" to={redirect[rol || 'investor']} />
                {/* <Redirect path="*" to="/not-found" /> */}
        </Switch>
    )
}

export default Routes;