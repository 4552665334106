import axios from "axios";

export async function getCurrencyCripto() {
    const URL = process.env.REACT_APP_API
    try {
        const response = await axios.get(URL + '/coins')
        return response.data
    } catch (error) {
        return {error}   
    }
}