import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../redux/actions";

const Logout = () => {
    // REDUX
    const dispatch = useDispatch();
    const authToken = useSelector((state) => state.auth.authToken);

    if (authToken !== null) {
        dispatch(authActions.logoutUser());
    }

    return null;
};

export default Logout;
