import { useEffect, useState } from "react"
import { getContracts } from "../redux/api/contracts"

// Estados
// vencidos
// vigentes
// pendientes
// false = todos
const useContracts = (status = false) => {

    const [contracts, setContracts] = useState(null)

    useEffect(() => {
        contracts === null && getData(status)
    }, [])
    

    const getData = async (status) => {
        const request = await getContracts(status)
        if (request.error) {
            setContracts(false)
        }
        else{
            setContracts(request)
        }
    }

    return {
        contracts: contracts?.contrats || null,
        total: contracts?.totalAmmount || 0,
        profit: contracts?.totalProfit || 0,
        date: parseInt(new Date(contracts?.date).toLocaleDateString().split('/')[0]) > 3 ? true : false
    }
}

export default useContracts