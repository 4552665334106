import React from 'react';
import styled from 'styled-components';
import 'react-phone-input-2/lib/style.css'

import { ErrorMessage, Field } from 'formik';
import PhoneInput from 'react-phone-input-2';

const DefaultPhone = (props) => {
    const { name, label, value, setFieldValue } = props;

    // RETURN
    return (
        <Field
            as={PhoneInputFake}
            name={name}
            value={value}
            onChange={(newValue) => {
                setFieldValue(name, newValue);
            }}
            helperText={<ErrorMessage name={name} component={CustomError} />}
            country={'ar'}
            regions={['america', 'europe']}
            countryCodeEditable={false}
            autoFormat={true}
            specialLabel={label}
            inputStyle={{ width: '100%', borderColor: 'inherit' }}
            {...props}
        />
    );
};

export default DefaultPhone;

const CustomError = styled.span`
    font-size: 0.75rem;
    font-weight: 700;
`;

const PhoneInputFake = styled(PhoneInput)`
    /* border: 1px solid white; */
    width: 100%;
    max-width: auto;
    height: 37.63px;
    border-radius: 4px;
    .flag-dropdown{
        background: #2b3648;
        :hover{
            background: black;
        }
    }
    .form-control{
        background: #2b3648;
        color: white;
        height: 37.63px;
        border-top-right-radius: 3.5px;
        border-top-left-radius: 3.5px;
    }
    .country-list {
        background: #2b3648;
    }
    .selected-flag:hover{
        background: #2b3648;
    }
`