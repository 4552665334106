import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const MenuAdmin = (props) => {

    const { title, to } = props

    const history = useHistory()

    return (
        <Wrapper onClick={() => history.push(to)}>{title}</Wrapper>
    )
}

export default MenuAdmin

const Wrapper = styled.div`
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-transform: uppercase;
    font-size: 34px;
`