import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import DefaultDialog from '../../common/DefaultDialog'
import { Img } from '../../common/Image'
import UpdatePhoto from '../../ui/organisms/profile/UpdatePhoto'

const ImageProfile = () => {

    const { user } = useSelector(state => state.auth)

    return (<ImageProfileWrapper>
        <Img w="100%" h="100%" src={user.image || process.env.REACT_APP_AVATAR} />
        <DefaultDialog component={<UpdatePhoto/>} title="CAMBIAR FOTO DE PERFIL" variant={'text'} size="small" color='primary' fullWidth={true}/>
    </ImageProfileWrapper>)
}

export default ImageProfile

const ImageProfileWrapper = styled.div`
    max-width: calc(100% - 2px);
    height: 245px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    img{
        object-position: center;
        object-fit: cover;
        border: 1px solid #313C4E;
        border-radius: 4px;
    }
`