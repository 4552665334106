import { useEffect, useState } from "react"
import { getInvestments } from "../redux/api/investment"

// Estaoos
// vencidos
// vigentes
// pendientes
// false = todos
const useInvestments = (status = false) => {

    const [investments, setInvestments] = useState(null)

    useEffect(() => {
        investments === null && getData(status)
    }, [])
    

    const getData = async (status) => {
        const request = await getInvestments(status)
        if (request.error) {
            setInvestments(false)
        }
        else{
            setInvestments(request)
        }
    }

    return {
        investments: investments?.contrats || null,
        total: investments?.totalAmmount || 0,
        profit: investments?.totalProfit || 0,
        date: parseInt(new Date(investments?.date).toLocaleDateString().split('/')[0]) > 3 ? true : false
    }
}

export default useInvestments