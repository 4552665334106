import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { alertsActions } from '../../../../redux/actions';
import { registerUser } from '../../../../redux/api/auth';
import { DefaultButton } from '../../../common/DefaultButton';
import DefaultField from '../../../common/DefaultField';
import DefaultPhone from '../../../common/DefaultPhone';
import { registerSchema } from '../../../schemas/auth';

const FormRegister = () => {

    const [loading, setLoading] = useState(false)

    // FORMIK
    const initialValues = {
        name: '',
        lastName: '',
        email: '',
        password: '',
        phone: '',
        changepassword: ''
    }

    // STATE
    const [showPassword, setShowPassword] = useState(false);

    // REDUX
    const dispatch = useDispatch()

    // FUNCTIONS
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async values => {
        delete values.changepassword
        setLoading(true)
        const request = await registerUser({...values, phone: `+${values.phone}`})
        setLoading(false)
        if (request.error) {
            dispatch(alertsActions.showSnack({
                variant: 'error',
                message: request?.error?.response?.data?.message
            }))
        }
        else{
            dispatch(alertsActions.showSnack({
                variant: 'success',
                message: '¡Éxito! Revisa tu casilla de correo para validar la cuenta.'
            }))
        }
    }

    return (
        <FormWrapper>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={registerSchema}
            >
                {({ values, setFieldValue }) => (
                    <FormContainer>
                        <Grid>
                            <DefaultField
                                name="name"
                                type="text"
                                label="Nombre"
                                variant='outlined'
                                required
                            />
                            <DefaultField
                                name="lastName"
                                type="text"
                                label="Apellido"
                                variant='outlined'
                                required
                            />
                        </Grid>
                        {/** EMAIL */}
                        <DefaultFieldFake
                            name="email"
                            type="email"
                            label="Mail"
                            variant='outlined'
                            required
                        />
                        {/** Password */}
                        <DefaultFieldFake
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            label="Contraseña"
                            required
                            variant='outlined'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            onClick={toggleShowPassword}
                                        >
                                            {showPassword ? (
                                                <Visibility fontSize="small" />
                                            ) : (
                                                <VisibilityOff fontSize="small" />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <DefaultFieldFake
                            name="changepassword"
                            type={showPassword ? 'text' : 'password'}
                            label="Repetir contraseña"
                            required
                            variant='outlined'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            onClick={toggleShowPassword}
                                        >
                                            {showPassword ? (
                                                <Visibility fontSize="small" />
                                            ) : (
                                                <VisibilityOff fontSize="small" />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <DefaultPhoneFake
                            name="phone"
                            label="Teléfono celular"
                            value={values.phone}
                            setFieldValue={setFieldValue}
                        />
                        {!loading ? (
                            <DefaultButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                id="submit"
                            >
                                REGISTRAME
                            </DefaultButton>
                        ) : (
                            <DefaultButton
                                variant="contained"
                                color="primary"
                                id="submit"
                                disabled
                            >
                                CARGANDO...
                            </DefaultButton>
                        )}


                    </FormContainer>
                )}
            </Formik>
        </FormWrapper >
    )
}

export default FormRegister

const FormWrapper = styled.div`

`

const FormContainer = styled(Form)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin: 20px auto;
    width: 90%;
    #submit{
        margin: 0 auto;
        width: 150px;
        margin-top: 20px;
    }
`

const DefaultFieldFake = styled(DefaultField)`
    max-width: 360px;
    width: 360px;
`

const DefaultPhoneFake = styled(DefaultPhone)`
    max-width: 360px;
    width: 360px;
`

const Grid = styled.div`
    width: calc(100% - 10px);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
`