import React, { useState } from 'react'
import styled from 'styled-components'
import ContractsList from '../../components/templates/contracts/ContractsList'
import ContractsRender from '../../components/templates/contracts/ContractsRender'
import { getContratById } from '../../redux/api/investment'


const Contracts = () => {

    const [contract, setContract] = useState(null)
    const [contractService, setContractService] = useState(null)

    const handleInfoContract = contract => {
        setContractService(null)
        setContract(contract)
        getContractData(contract.id)
    }

    const getContractData = async (id) => {
        const request = await getContratById(id)
        if (request.error) {
            alert('Error')
        }
        else{
            setContractService(request)
        }
    }

    const handleReset = () => {
        setContract(null)
        setContractService(null)
    }

    return (
        <ContractsContainer>
            <ContractsList handleReset={handleReset} handleInfoContract={handleInfoContract} />
            <ContractsRender contract={contract} values={contractService} />
        </ContractsContainer>
    )
}

export default Contracts

const ContractsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
`