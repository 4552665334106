import styled from "styled-components";

export const Text = styled.div`
    font-size: ${p => p.fontSize};
    font-weight: ${p => p.fontWeight};
    color: ${p => p.color};
    text-align: ${p => p.textAlign};
`

export const Span = styled.span`
    font-size: ${p => p.fontSize};
    font-weight: ${p => p.fontWeight};
    color: ${p => p.color};
    text-align: ${p => p.textAlign};
`