import { Form, Formik } from 'formik'
import React from 'react'
import { DefaultButton } from '../../common/DefaultButton';
import { DefaultCard } from '../../common/DefaultCard'
import DefaultField from '../../common/DefaultField';
import { simulatorSchema } from '../../schemas/simulator';
import styled from 'styled-components'
import DefaultSelect from '../../common/DefaultSelect';
import { Text } from '../../common/Text';
import useStates from '../../../hooks/useStates';
import DefaultPhone from '../../common/DefaultPhone';

const SimulatorCreate = (props) => {

    const { handleValues } = props

    const codes = [
        {
            id: 3,
            name: "3 meses"
        },
        {
            id: 6,
            name: "6 meses"
        },
        {
            id: 12,
            name: "12 meses"
        }
    ]
    const initialValues = {
        name: '',
        lastName: '',
        dni: '',
        email: '',
        adress: '',
        state: 0,
        phone: '',
        package: 3,
        cash: 300
    };

    const { states } = useStates()

    const handleSubmit = (values) => handleValues(values)

    return (
        <Wrapper h="415px">
            <Text color="white" fontSize="20px" fontWeight="500">Simulador de contratos</Text>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={simulatorSchema}
            >
                {({ values, setFieldValue }) => (
                    <FormContainer>
                        {/** Password */}
                        <Data columns="1fr 1fr">
                            <DefaultField
                                name="name"
                                type="text"
                                label="Nombre"
                                variant='outlined'
                                required
                            />
                            <DefaultField
                                name="lastName"
                                type="text"
                                label="Apellido"
                                variant='outlined'
                                required
                            />
                            <DefaultField
                                name="dni"
                                type="text"
                                label="N° de documento"
                                variant='outlined'
                                required
                            />
                            <DefaultPhoneFake
                                name="phone"
                                label="Teléfono celular"
                                value={values.phone}
                                setFieldValue={setFieldValue}
                            />
                        </Data>
                        <Data columns="1fr">
                            <DefaultField
                                name="email"
                                type="text"
                                label="Mail"
                                variant='outlined'
                                required
                            />
                        </Data>
                        <Data columns="1fr 1fr">
                            <DefaultField
                                name="adress"
                                type="text"
                                label="Direccion"
                                variant='outlined'
                                required
                            />
                            {states ? <DefaultSelect
                                name="state"
                                type="text"
                                variant='outlined'
                                required
                                options={states}
                            /> : <DefaultSelect
                                type="text"
                                variant='outlined'
                                disabled
                                options={[]}
                            />
                            }
                            <DefaultSelect
                                name="package"
                                type="text"
                                variant='outlined'
                                required
                                options={codes}
                            />
                            <DefaultField
                                name="cash"
                                type="number"
                                label="(USD) Monto a invertir"
                                variant='outlined'
                            />
                        </Data>
                        <DefaultButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            id="submit"
                        >
                            SIMULAR CONTRATO
                        </DefaultButton>

                    </FormContainer>)}
            </Formik>
        </Wrapper>
    )
}

export default SimulatorCreate

const Wrapper = styled(DefaultCard)`
    display: flex;
    gap: 25px;
    flex-direction: column;
`

const FormContainer = styled(Form)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    /* margin: 20px auto;
    width: 90%; */
`

const Data = styled.div`
    display: grid;
    gap: 15px;
    grid-template-columns: ${p => p.columns};
    @media(max-width: 768px){
        display: flex;
        flex-direction: column;
    }
`

const DefaultPhoneFake = styled(DefaultPhone)`
    max-width: 100%;
    width: 100%;
`