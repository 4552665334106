import React from 'react'
import styled from 'styled-components'
import useContracts from '../../../hooks/useContracts'
// import useCashUser from '../../../hooks/useCashUser'
import { DefaultButton } from '../../common/DefaultButton'
// import { DefaultButton } from '../../common/DefaultButton'
import { Text } from '../../common/Text'

const AdvisorHome = () => {

    // const { values } = useCashUser()

    const { profit } = useContracts('vigentes')

    // if (values === null) {
    //     return <PageLoading h="100%"/>
    // }

    // if (values === false) {
    //     return <ErrorPage color="#212936"/>
    // }

    return (
        <Container>
            <Body>
                <Text fontSize="20px" fontWeight="500">Comisiones totales</Text>
                <Text fontSize="34px" fontWeight="500">${profit?.toFixed(2)}</Text>
            </Body>
            <Text color="#313C4E" fontWeight="400" fontSize="12px">Retiro disponible del 11 al 20 de cada mes.</Text>
            <DefaultButton color='secondary' variant='contained'>SOLICITAR RETIRO</DefaultButton>
        </Container>
    )
}

export default AdvisorHome

const Container = styled.div`
    padding-top: 10px;
    height: 220px;
    max-height: auto;
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    flex-direction: column;
    gap: 16px;
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

// const Footer = styled.div`
//     height: 20%;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     gap: 1rem;
// `