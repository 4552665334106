import React from "react";
import { Redirect, Route } from "react-router-dom";

import { connect } from "react-redux";
import { useHelpChat } from "../hooks/useHelpChat";
// import Layout from "../components/shared/layout/Layout";
// import Layout from "../components/shared/layout/Layout";

const PublicRoute = (props) => {
	const { component: Component, isAuthenticated, rol, ...rest } = props;

	const redirect = {
		adviser: '/adviser',
		investor: '/home'
	}

	useHelpChat(false, isAuthenticated, 'public')

	// RETURN
	return (
		<>
			<Route
				{...rest}
				render={(props) => isAuthenticated ? <Redirect path="/" to={redirect[rol]} /> : <Component {...props} />}
			/>
		</>
	);
};

const mapStateToProps = (state) => ({
	isAuthenticated: !!state.auth.authToken,
	rol: state.auth.rol
});

export default connect(mapStateToProps)(PublicRoute);
