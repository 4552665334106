import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import store, { persistor } from "./redux/store/store";
import reportWebVitals from './reportWebVitals';
import Application from './Application';
import * as _redux from "./redux";
import axios from "axios";
import { polyfill } from './polyfill';

_redux.setupAxios(axios, store);

polyfill()

ReactDOM.render(
  <React.StrictMode>
    <Application store={store} persistor={persistor}/>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
