import React, { useState } from 'react'
import styled from 'styled-components'
import { DefaultCard } from '../../components/common/DefaultCard'
import DefaultSearch from '../../components/common/DefaultSearch'
import DefaultTable from '../../components/common/DefaultTable'
import PageLoading from '../../components/common/PageLoading'
import { Text } from '../../components/common/Text'
import useContracts from '../../hooks/useContracts'

export const Clients = () => {

  const columns = [
    { id: "term", label: "" },
    { id: "name", label: "Nombre y apellido" },
    { id: "dateFrom", label: "Fecha" },
    { id: "dateTo", label: "Vence" },
    { id: "amount", label: "Monto" },
    // { id: "ganancia", label: "Ganancia" },
    { id: "status", label: "Estado" },
  ]

  const { contracts } = useContracts()
  const [filtered, setFiltered] = useState(null)

  const tableRender = () => {
    if (contracts) {
      const result = contracts.map((item, index) => {
        let isDefeated = false
        const nowDate = new Date().getTime()
        const dateTo = new Date(item.dateTo).getTime()
        if (nowDate > dateTo && (item.status !== 'PENDIENTE' && item.status !== 'RECHAZADO')) {
          isDefeated = true
        }
        return {
          ...item,
          name: `${item?.investor?.name} ${item?.investor?.lastName}`,
          term: <Id>{(item.term / 30)}</Id>,
          termNumber: (item.term / 30) ,
          amount: `$${item.amount}`,
          dateFrom: item.status !== 'PENDIENTE' && item.status !== 'RECHAZADO' ? new Date(item.dateFrom).toLocaleDateString() : '-',
          dateTo: item.status !== 'PENDIENTE' && item.status !== 'RECHAZADO' ? new Date(item.dateTo).toLocaleDateString() : '-',
          statusFilter: isDefeated && (item.status !== 'PENDIENTE' && item.status !== 'RECHAZADO') ? 'vencido' : item.status.toLowerCase(),
          status: <Text color={isDefeated ? '#D24335' : item.status === 'PENDIENTE' ? '#B7950B' : item.status === 'APROBADO' ? '#58D68D' : '#D24335'}>{isDefeated && (item.status !== 'PENDIENTE' && item.status !== 'RECHAZADO') ? 'Vencido' : item.status.toTitleCase()}</Text>
        }
      })
      return result
    }
  }

  const handleValues = (filtered) => {
    setFiltered(filtered)
  }

  if (!contracts) {
    return <DefaultCard>
      <PageLoading />
    </DefaultCard>
  }

  return (
    <InvestmentsWrapper>
      <DefaultCard>
        <Header>
          <Text fontSize="20px" color="#fff">Cartera de clientes</Text>
          <DefaultSearch title="Buscar cliente" handleValues={handleValues} filterNames={['termNumber', 'name', 'amount', 'statusFilter']} values={tableRender()} />
        </Header>
        <DefaultTable columns={columns} rows={filtered || tableRender()} pageSize="10" footer={true} />
      </DefaultCard>
    </InvestmentsWrapper>
  )
}

const InvestmentsWrapper = styled.div``

const Id = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #B7950B;
    color: #212936;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
`