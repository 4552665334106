import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import CardCodes from '../../ui/organisms/codes/CardCodes'

const CodesTemplate = () => {

    const { user } = useSelector(state => state.auth)

    return (
        <Container>
            <CardCodes month={3} code={user.code90}/>
            <CardCodes month={6} code={user.code180}/>
            <CardCodes month={12} code={user.code360}/>
        </Container>
    )
}

export default CodesTemplate

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
`