import { Avatar, IconButton } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import useLocatioPage from '../../../hooks/useLocatioPage'
import { useViewPort } from '../../../hooks/useViewPort'
import { Text } from '../../common/Text'
import MenuIcon from '@material-ui/icons/Menu';

const Navbar = (props) => {

    const { handleMenu } = props

    const { title } = useLocatioPage()
    const { width } = useViewPort()

    const { user } = useSelector(state => state.auth)

    return (
        <NavbarWrapper>
            {width <= 768 && <IconButton onClick={() => handleMenu(true)} color="primary" aria-label="upload picture" component="span">
                <MenuIcon />
            </IconButton>}
            <Text fontSize="24px" color="#AFBDD1">{title}</Text>
            <Avatar alt={user.name[0]} src={user.image || process.env.REACT_APP_AVATAR} />
        </NavbarWrapper>
    )
}

export default Navbar

const NavbarWrapper = styled.div`
    height: 64px;
    width: calc(100% - 256px - 36px);
    background: #2B3648;
    border-bottom: 2px solid #313c4e;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14);
    position: fixed;
    left: 256px;
    top: 0;
    display: flex;
    justify-content:  space-between;
    align-items: center;
    padding: 0 18px;
    @media(max-width: 768px) {
        width: calc(100% - 36px);
        left: 0;
    }
`