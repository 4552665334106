import axios from "axios";
import { BASEURL } from "./config";

const url = BASEURL

export function loginUser(payload) {
	return axios.post(`${url}/auth/signIn`, payload);
}

export async function registerUser(payload) {
	try {
		const req = await axios.post(`${url}/auth/signUp`, payload)
		return req.data
	} catch (error) {
		return { error }
	}
}

export async function updateProfile(payload, id) {
	try {
		const req = await axios.patch(`${url}/users/${id}`, payload)
		return req.data
	} catch (error) {
		return { error }
	}
}

export async function updateImage(formData) {
	try {
		const req = await axios.post(`${url}/users/image`, formData)
		return req.data
	} catch (error) {
		return { error }
	}
}