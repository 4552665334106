import * as types from '../types/alerts';

const actions = {};

actions.showSnack = (payload) => {
    return {
        type: types.SHOW_SNACK,
        payload
    };
};

actions.closeSnack = (payload) => {
    return {
        type: types.CLOSE_SNACK,
        payload
    };
};

export { actions };
