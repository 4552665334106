import React from 'react'
import styled from 'styled-components';
import { DefaultCard } from '../../../components/common/DefaultCard';
import AdvisorHome from '../../../components/templates/home/AdvisorHome';
import ListClients from '../../../components/templates/home/ListClients';
import PrefitInfo from '../../../components/templates/home/PrefitInfo';
import Quotes from '../../../components/templates/home/Quotes';

const HomeAdviser = () => {

    return (
        <Wrapper>
            <DefaultCard bg="#F1C40F" border="#B7950B" color="#212936" area="cash" h="220px">
                <AdvisorHome/>
            </DefaultCard>
            <PrefitInfo/>
            <DefaultCard area="cripto" h="308px">
                <Quotes/>
            </DefaultCard>
            <DefaultCard area="list">
                <ListClients/>
            </DefaultCard>
        </Wrapper>
    )
}

export default HomeAdviser

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 220px auto;
    grid-template-areas:"cash   cash   list list list list"
                        "prefit prefit list list list list"
                        "cripto cripto list list list list";
    gap: 1rem;
    @media(max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`