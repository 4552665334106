import React, { useState } from 'react'
import styled from 'styled-components'
import ContractsSimulatorRender from '../../components/templates/simulator/ContractSimulatorRender'
import SimulatorCreate from '../../components/templates/simulator/SimulatorCreate'

const Simulator = () => {

    const [info, setInfo] = useState(null)

    const handleValues = data => {
        console.log(data);
        setInfo(data)
    }

    return (
        <ContractsContainer>
            <SimulatorCreate handleValues={handleValues}/>
            <ContractsSimulatorRender values={info}/>
        </ContractsContainer>
    )
}

export default Simulator

const ContractsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
`