import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

// Reducers
import { auth } from "./reducers/auth";
import { alerts } from "./reducers/alerts";



// Sagas
import { authWatcher } from "./sagas/auth";



export const rootReducer = combineReducers({
	auth: auth,
	alerts: alerts
});

export function* rootSaga() {
	yield all([
		authWatcher(),
	]);
}
