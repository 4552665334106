import { useDispatch, useSelector } from 'react-redux';
import { alertsActions } from '../redux/actions';

export const useSnack = () => {
    //   const [snackBar, setSnackBar] = useState({ show: false, variant: 'success', message: '' })
    //   const [show, setShow] = useState(null)

    const dispatch = useDispatch();
    const { isOpen, variant, message } = useSelector((state) => state.alerts);

    const closeSnack = () => {
        dispatch(alertsActions.closeSnack(variant));
    };

    return { isOpen, variant, message, closeSnack };
};
